<template>
	<SidebarLayout>
		<div>
			<!-- HEADING -->
			<ScenarioHead :scenario="scenario" :is_edit_page="true" @togglePanel="toggleSettingsPanel" @run="runScenario"></ScenarioHead>

			<!-- CONTENT -->
			<div class="w-11/12 2xl:w-10/12 mx-auto">
				<Block :border="true">
					<AppTabs :tabList="tabList" @tab-change="handleTabChange">
						<!-- STEPS TAB -->
						<template #tabPanel-0>
							<div v-if="isLoading" class="bg-gray-100 rounded-md text-center text-sm font-light text-gray-400 py-8">Loading...</div>
							<!-- STEPS WRAPPER -->
							<div v-else v-for="(step, index) in sortedSteps" :key="index" class="mb-16">
								<StepHeader :step="step" :current_step_id="step.id" :index="index" @deleteStep="deleteStep" @updateStep="updateStep"></StepHeader>
								<ActionsTable :step="step" v-on:stepChange="updateStep"></ActionsTable>
							</div>

							<!-- NO STEP BLOCK -->
							<div v-show="scenario?.steps?.length === 0" class="w-full flex flex-wrap items-center justify-center bg-gray-100 rounded-md py-6 my-6">
								<div class="w-full text-center text-gray-400 mb-6">This scenario is empty</div>
								<button type="button" @click="toggleImportPanel(true)" class="button-gto text-white bg-custom-blue border border-custom-blue">
									<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 xl:mr-2 xl:-ml-2" viewBox="0 0 20 20" fill="currentColor">
										<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd" />
									</svg>
									<span class="w-full sm:w-auto ml-1">Import steps</span>
								</button>
							</div>
							<!-- SCENARIO BUTTONS WRAPPER -->
							<div class="flex flex-wrap sm:flex-nowrap justify-end w-full pt-8">
								<button type="button" @click="createStep" class="w-full sm:w-auto justify-center sm:justify-start button-gto text-white bg-custom-blue border border-custom-blue">
									<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 -ml-2" viewBox="0 0 20 20" fill="currentColor">
										<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd" />
									</svg>

									Add Step
								</button>
							</div>
						</template>

						<!-- TAGS TAB -->
						<template #tabPanel-1>
							<!-- TAGS WRAPPER -->
							<Block :title="'Manage tag assertions'" :subtitle="'Select a step to display the tags triggered during the run'" :border="true" v-show="steps?.length">
								<div class="flex w-full flex-wrap justify-start">
									<!-- TAGS STEP SELECTOR -->
									<select id="step_name" name="step_name" v-model="current_step_id" class="button-gto text-white bg-custom-blue pl-6 pr-20 border rounded rounded-md appearance-none custom-select">
										<option disabled value="">Select a step</option>
										<option selected v-for="(step, index) in steps" :key="index" :value="step.id">
											{{ step.name }}
										</option>
									</select>
									<button
										type="button"
										@click="getTags"
										class="w-full sm:w-auto justify-center sm:justify-start button-gto text-white bg-main hover-bg-main sm:mx-2 my-2 sm:my-0"
										:disabled="isLoadingTags"
										:class="isLoadingTags ? 'cursor-not-allowed bg-gray-100 text-gray-300 hover:bg-gray-100 hover:text-gray-300' : 'cursor-pointer bg-main text-white'"
									>
										<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 -ml-2" :class="isLoadingTags ? 'rotating' : ''" viewBox="0 0 20 20" fill="currentColor">
											<path
												fill-rule="evenodd"
												d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
												clip-rule="evenodd"
											/>
										</svg>
										Refresh Tags
									</button>
									<!-- TAGS TABLE -->
									<!-- <ScenarioTagsTable :steps="steps" :tagsNetworks="tagsNetworks" :current="current_step_id" :isSelected="current_step_id !== ''" v-on:getTags="getTags"></ScenarioTagsTable> -->
									<div class="w-full mt-12">
										<p class="w-full text-lg">{{ 'Tags' }}</p>
									</div>
									<TagsTable :steps="steps" :tagsNetworks="tagsNetworks" :current="current_step_id" :isSelected="current_step_id !== ''" v-on:getTags="getTags" :readOnly="false"></TagsTable>
								</div>

								<!-- NO TAGS BLOCK -->
								<div v-show="!steps?.length" class="w-full flex items-center justify-center bg-gray-100 text-gray-400 rounded-md py-6 my-6">No tags for this scenario</div>
								<div class="mt-12">
									<p class="w-full text-lg">{{ 'Assertions' }}</p>
								</div>

								<div v-show="getAssertions('assertions_tags')?.length" class="w-full mt-4 border border-2 border-gray-50 rounded rounded-md overflow-hidden">
									<div class="flex justify-between items-center">
										<div class="w-1/4 py-2 px-4 text-gray-400 uppercase text-xs text-left bg-gray-50 pl-10">step</div>
										<div class="w-1/4 py-2 px-4 text-gray-400 uppercase text-xs text-left bg-gray-50 pl-10">network</div>
										<div class="w-1/4 py-2 px-4 text-gray-400 uppercase text-xs text-left bg-gray-50 pl-10">event name</div>
										<div class="w-1/4 py-2 px-4 text-gray-400 uppercase text-xs text-right bg-gray-50">edit</div>
									</div>
									<div v-if="!getAssertions('assertions_tags')?.length" class="w-full flex items-center justify-center border-gray-50 text-gray-400 rounded-md py-6 text-sm">No tags for this step</div>
									<div v-for="assertion in getAssertions('assertions_tags').filter((assertion) => assertion.step_id === current_step_id)" :key="assertion.id" class="my-2">
										<div class="flex items-center">
											<div class="w-1/4 py-2 px-4 text-gray-600 text-left pl-10" :title="''">{{ stepName(assertion.step_id) }}</div>
											<div class="w-1/4 py-2 px-4 text-gray-600 text-left pl-10" :title="''">{{ assertion.network }}</div>
											<div class="w-1/4 py-2 px-4 text-gray-600 text-left break-none pl-10" :title="''">{{ assertion.value }}</div>

											<div class="w-1/4 py-2 px-4 text-gray-600 text-right break-none justify-end" :title="''">
												<!-- <button class="bg-gray-100 hover:bg-blue-100 hover:text-blue-400 text-gray-400 rounded-md p-2" style="">
													<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
														<path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path>
													</svg>
												</button> -->
												<button @click="deleteTagAssertion(assertion.id)" class="bg-gray-100 hover:bg-red-100 hover:text-red-400 text-gray-400 rounded-md p-2 ml-2" style="">
													<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
														<path
															fill-rule="evenodd"
															d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
															clip-rule="evenodd"
														></path>
													</svg>
												</button>
											</div>
										</div>
									</div>
									<div v-if="!getAssertions('assertions_tags').filter((assertion) => assertion.step_id === current_step_id)?.length" class="w-full flex items-center justify-center border-gray-50 text-gray-400 rounded-md py-6 text-sm">
										No assertion for this step
									</div>
								</div>
							</Block>
							<!-- NEW KEY INPUTS -->
							<Block class="mt-6 min-w-full" :border="true" :title="'Add new tag assertion'" :subtitle="'Insert the event names of the tags per step'">
								<div class="w-full flex flex-wrap items-start my-6">
									<!-- TAGS STEP SELECTOR -->
									<div class="flex flex-col">
										<label class="uppercase text-gray-400 text-xs text-light mb-2 pl-1">step name</label>
										<select name="step_name" v-model="current_step_id" class="button-gto text-white bg-custom-blue pl-6 pr-20 border rounded rounded-md appearance-none custom-select">
											<option disabled value="">Select a step</option>
											<option selected v-for="(step, index) in steps" :key="index" :value="step.id">
												{{ step.name }}
											</option>
										</select>
									</div>

									<!-- NETWORK INPUT -->
									<div class="flex flex-col ml-4">
										<label class="uppercase text-gray-400 text-xs text-light mb-2 pl-1">network</label>
										<select name="network_name" v-model="new_tag_assertion.network" class="button-gto text-white bg-custom-blue pl-6 pr-20 border rounded rounded-md appearance-none custom-select">
											<option disabled value="">Select a network</option>
											<option selected>GA4</option>
										</select>
									</div>
									<!-- VALUE INPUT -->
									<div class="flex flex-col mx-4">
										<label class="uppercase text-gray-400 text-xs text-light mb-2 pl-1">event name</label>
										<div class="flex flex-wrap flex-col sm:flex-row justify-between">
											<input type="text" required="required" v-model="new_tag_assertion.value" class="input-gto pl-6-i" placeholder="Enter an event name" />
										</div>
									</div>
									<div class="flex flex-col">
										<!-- ADD TAG ASSERTION BUTTON -->
										<label class="uppercase text-gray-400 text-xs text-light mb-2 pl-1">add</label>
										<button
											:disabled="!new_tag_assertion.value && !new_tag_assertion.network"
											@click="new_tag_assertion.value && new_tag_assertion.network ? createAssertion() : null"
											type="button"
											class="button-gto mt-0 flex justify-center"
											:class="new_tag_assertion.value && new_tag_assertion.network ? 'text-white bg-custom-blue' : 'bg-gray-100 text-gray-400 border border-gray-200 cursor-not-allowed'"
										>
											Add
										</button>
									</div>
								</div>
							</Block>
						</template>

						<!-- ASSERTIONS TAB -->
						<template #tabPanel-2>
							<!-- DATALAYER ASSERTIONS -->
							<Block title="DataLayer assertions" :border="true" class="relative">
								<button
									type="button"
									@click="toggleEventPanel"
									class="absolute top-5 right-5 sm:w-auto justify-center sm:justify-start button-gto text-white bg-main hover-bg-main sm:mx-2 my-2 sm:my-0"
									:class="isLoadingTags ? 'bg-custom-orange' : ''"
								>
									<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 -ml-2" viewBox="0 0 20 20" fill="currentColor">
										<path
											fill-rule="evenodd"
											d="M2 9.5A3.5 3.5 0 005.5 13H9v2.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 15.586V13h2.5a4.5 4.5 0 10-.616-8.958 4.002 4.002 0 10-7.753 1.977A3.5 3.5 0 002 9.5zm9 3.5H9V8a1 1 0 012 0v5z"
											clip-rule="evenodd"
										/>
									</svg>
									Import event assertions
								</button>
								<KeyTable :keys="storeAssertion?.assertions" @delete="deleteDataLayerAssertion" @create="addAssertion" @update="editAssertion"></KeyTable>
							</Block>
						</template>
					</AppTabs>

					<!-- RUN LOGS WRAPPER -->
					<div class="w-full py-4">
						<p class="w-full my-4 text-lg">Last run logs</p>
						<ScenarioLogs v-if="lastLogs" :logs="lastLogs"></ScenarioLogs>
						<div v-else class="w-full flex items-center justify-center bg-gray-100 text-gray-400 rounded-md py-6 my-6">This scenario has never ran</div>
					</div>
				</Block>
			</div>

			<!-- SETTINGS PANEL -->
			<SidePanel @togglePanel="toggleSettingsPanel" :title="'Scenario Settings'" :isVisible="show_settings_panel">
				<!-- INFORMATIONS -->
				<Block :title="'Informations'" :border="true">
					<!-- NAME -->
					<div class="w-full my-2">
						<div class="uppercase text-sm font-light text-gray-500">Name</div>
						<input
							@keyup.enter="updateScenarioOptional()"
							v-model="current_scenario.name"
							type="text"
							name="scenarioName"
							id="scenarioName"
							class="block w-full sm:text-sm border border-gray-300 rounded-md px-2 py-3 focus:outline-none"
							placeholder="Enter the name of the scenario"
						/>
					</div>

					<!-- DESCRIPTION -->
					<div class="w-full my-2">
						<div class="uppercase text-sm font-light text-gray-500">Description</div>
						<input
							@keyup.enter="updateScenarioOptional()"
							v-model="current_scenario.description"
							type="text"
							name="scenarioDesc"
							id="scenarioDesc"
							class="block w-full sm:text-sm border border-gray-300 rounded-md px-2 py-3 focus:outline-none"
							placeholder="Enter the description of the scenario"
						/>
					</div>

					<!-- VALIDATE NAME & DESCRIPTION -->
					<button @click="updateScenarioOptional()" type="button" class="button-gto text-white bg-custom-blue button-gto--custom ml-auto mt-4 w-20 flex justify-center">OK</button>
				</Block>

				<!-- DEVICE -->

				<Block title="Device" subtitle="Set on which type of device the scenario should run." :border="true">
					<select @change="updateScenarioOptional()" v-model="current_scenario.device" class="button-gto text-white bg-custom-blue w-full pl-6 pr-20 mx-auto border rounded rounded-md appearance-none custom-select">
						<option disabled value="">Select device</option>
						<option value="mobile">Mobile</option>
						<option value="desktop">Desktop</option>
					</select></Block
				>

				<!-- SCHEDULE -->
				<Block title="Run schedule" subtitle="Set how often the scenario will be run. " :border="true"
					><select @change="updateScenarioOptional()" v-model="current_scenario.frequency" class="button-gto text-white bg-custom-blue w-full pl-6 pr-20 mx-auto border rounded rounded-md appearance-none custom-select">
						<option selected disabled value="">Select frequency</option>
						<!-- <option value="* * * * *">Every minutes</option> -->
						<option value="0 * * * *">Every hours</option>
						<option value="0 12 * * *">Every days</option>
						<option value="0 12 * * 1">Every weeks</option>
						<option value="0 12 1 * *">Every months</option>
					</select>
					<button @click="removeFrequency()" type="button" class="button-gto text-white bg-custom-blue button-gto--custom ml-auto mt-4 w-20 flex justify-center">Reset</button>
				</Block>

				<!-- ALERTS -->
				<Block title="Want to be alerted?" subtitle="Choose how many times the scenario can fail before sending an alert." :border="true">
					<div class="flex flex-wrap justify-between w-full mb-4">
						<div class="w-3/4">
							<input type="number" min="1" max="100" class="input-gto pl-6-i w-full" placeholder="Enter value" v-model="current_scenario.max_fails" />
						</div>
					</div>
					<p class="w-full mb-2 text-sm text-gray-500">Set where the alerts should be sent.</p>

					<div class="flex flex-wrap flex-col sm:flex-row justify-between w-full my-2">
						<div class="w-full sm:w-3/4">
							<input @keyup.enter="addMail()" type="email" autocomplete="email" required="required" v-model="newMail" class="input-gto pl-6-i w-full" placeholder="Enter an email" />
						</div>
						<button @click="addMail()" type="button" class="button-gto text-white bg-custom-blue button-gto--custom sm:ml-4 mt-4 sm:mt-0 flex justify-center">Add</button>
					</div>
					<div v-show="mailError?.length" class="w-full flex items-center flex-wrap justify-between px-4 py-2 bg-red-100 rounded-sm my-1">
						<div class="text-sm text-red-400">{{ mailError }}</div>
						<div @click="mailError = ''" class="cursor-pointer">
							<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-red-400" viewBox="0 0 20 20" fill="currentColor">
								<path
									fill-rule="evenodd"
									d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
									clip-rule="evenodd"
								/>
							</svg>
						</div>
					</div>
					<div v-show="alerts?.length" class="flex flex-wrap justify-between w-full mt-4">
						<div class="w-full" v-for="(alert, index) in alerts" :key="index" :index="index">
							<div class="flex items-center flex-wrap justify-between w-full px-4 py-2 bg-gray-50 text-gray-400 text-sm rounded-sm my-1">
								<div>{{ alert.mail }} ({{ alert.frequency }})</div>
								<div @click="deleteAlerts(alert.id)" class="cursor-pointer">
									<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
										<path
											fill-rule="evenodd"
											d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
											clip-rule="evenodd"
										/>
									</svg>
								</div>
							</div>
						</div>
					</div>
				</Block>

				<!-- DELETE SCENARIO -->
				<Block title="Delete the scenario" subtitle="Are you sure you want to delete the scenario? This action is irreversible." :border="true">
					<button
						@click="deleteScenario()"
						type="button"
						class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
					>
						Delete Scenario
					</button>
				</Block></SidePanel
			>
			<!-- IMPORT PANEL -->
			<SidePanel @togglePanel="toggleImportPanel" :title="'Scenario Import'" :isVisible="show_import_panel">
				<!-- INFORMATIONS -->
				<Block :title="'Funnel import'" :border="true">
					<!-- NAME -->
					<div class="text-sm font-light text-gray-500 mb-4">Use the fnnl extension to record a scenario then paste the result below.</div>
					<!-- DESCRIPTION -->
					<div class="w-full my-2">
						<textarea
							@keyup.enter="importSteps()"
							v-model="import_steps"
							type="text"
							name="importSteps"
							id="importSteps"
							class="block w-full sm:text-sm border border-gray-300 rounded-md px-2 py-3 focus:outline-none h-48"
							placeholder="Paste here"
						></textarea>
					</div>

					<!-- VALIDATE NAME & DESCRIPTION -->
					<button @click="importSteps()" type="button" class="button-gto text-white bg-custom-blue button-gto--custom ml-auto mt-4 w-20 flex justify-center">Import</button>
				</Block>
			</SidePanel>
			<!-- MANAGE EVENTS -->
			<SidePanel @togglePanel="toggleEventPanel" :title="'Manage events'" :isVisible="show_event_panel">
				<!-- DEVICE -->
				<Block title="Events" subtitle="Link events and their assertions to the scenario" :border="true">
					<div class="flex flex-wrap w-full my-4">
						<select v-model="selectedEvent" class="button-gto text-white bg-custom-blue pl-6 pr-20 border border-custom-blue rounded rounded-md appearance-none custom-select">
							<option selected disabled value="">{{ isLoading ? 'Loading events...' : 'Select event' }}</option>
							<option v-for="event in events" :key="event.id" :value="event">{{ event.name }}</option>
						</select>
						<button
							@click="addEventToScenario"
							type="button"
							class="button-gto bg-custom-blue button-gto--custom ml-auto w-20 flex justify-center"
							:disabled="isLoadingEvents || !selectedEvent"
							:class="isLoadingEvents || !selectedEvent ? 'cursor-not-allowed bg-gray-100 text-gray-300 hover:bg-gray-100 hover:text-gray-300' : 'bg-main text-white'"
						>
							<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 -ml-2" viewBox="0 0 20 20" fill="currentColor">
								<path
									fill-rule="evenodd"
									d="M2 9.5A3.5 3.5 0 005.5 13H9v2.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 15.586V13h2.5a4.5 4.5 0 10-.616-8.958 4.002 4.002 0 10-7.753 1.977A3.5 3.5 0 002 9.5zm9 3.5H9V8a1 1 0 012 0v5z"
									clip-rule="evenodd"
								/>
							</svg>
							Import
						</button>
					</div>
					<KeyTable v-if="selectedEvent" :keys="selectedEvent.assertions?.map((assertion) => assertion.key)" :showCRUD="false"></KeyTable>
				</Block>
			</SidePanel>
		</div>
	</SidebarLayout>
</template>

<script>
	import { ref, computed, onMounted } from 'vue';
	import { useRoute } from 'vue-router';

	import { useScenarioStore } from '@/stores/scenarios';
	import { useAssertionStore } from '@/stores/assertions';

	import SidebarLayout from '@/layouts/SidebarLayout.vue';
	import StepHeader from '@/components/StepHeader.vue';
	import ScenarioHead from '@/components/ScenarioHead.vue';
	import ActionsTable from '@/components/ActionsTable.vue';
	import Block from '@/components/Block.vue';
	import TagsTable from '@/components/TagsTable.vue';
	import AppTabs from '@/components/AppTabs.vue';
	import ScenarioLogs from '@/components/ScenarioLogs.vue';
	import SidePanel from '@/components/SidePanel.vue';
	import router from '@/router';
	import KeyTable from '@/components/KeyTable.vue';

	export default {
		name: 'ScenarioView',
		props: {},
		emits: ['selectedSteps', 'getTags', 'togglePanel', 'toggleStatus', 'toggle_work_in_progress'],
		components: { StepHeader, SidebarLayout, ScenarioHead, ActionsTable, Block, TagsTable, AppTabs, ScenarioLogs, SidePanel, KeyTable },

		setup() {
			const store = useScenarioStore();
			const storeAssertion = useAssertionStore();
			const scenario = computed(() => store.scenario) || [];
			const steps = computed(() => store.scenario.steps) || [];
			const events = computed(() => storeAssertion.events) || [];
			const isLoading = computed(() => store.loader) || [];

			const route = useRoute();
			const scenarioID = route.params.id;

			const tabList = ref(['Steps', 'Tags', 'DataLayer']);
			const show_settings_panel = ref(false);
			const show_import_panel = ref(false);
			const show_event_panel = ref(false);

			const selectedEvent = ref('');

			const current_step_id = ref('');
			const new_tag_assertion = ref({
				type: '',
				network: '',
				key: '',
				value: '',
				match_any_request: true,
			});
			const new_datalayer_assertion = ref({
				type: '',
				key: '',
				values: '',
			});
			const new_tag_assertion_step = ref('');
			const new_datalayer_assertion_step = ref('');

			const current_scenario = ref({
				name: '',
				description: '',
				frequency: '',
				max_fails: '',
				device: '',
			});

			const sorted_steps = ref([]);

			const isLoadingTags = ref(false);
			const isLoadingEvents = ref(false);
			const isRunning = ref(false);
			const newMail = ref('');
			const mailList = ref([]);
			const mailError = ref('');
			const alerts = ref([]);
			const import_steps = ref([]);

			onMounted(async () => {
				store.setLoading(true);
				await store.getScenario(scenarioID);
				current_scenario.value = scenario.value;
				current_step_id.value = current_scenario.value.steps[0].id;
				store.setLoading(false);
			});

			const handleTabChange = async (tabID) => {
				if (tabID === 2) {
					store.setLoading(true);
					await storeAssertion.getEvents();
					await storeAssertion.getAssertions(scenarioID);
					store.setLoading(false);
				}
			};

			// COMPUTED

			const lastLogs = computed(() => {
				let last_logs = ['### Loading last logs...'];
				if (scenario.value?.runs) {
					const logs = scenario.value.runs.map((run) => run.results).map((result) => result.logs);
					last_logs = logs[logs.length - 1];
				}
				return last_logs;
			});

			const sortSteps = () => {
				return store.scenario?.steps?.sort((a, b) => (a.number > b.number ? 1 : -1)) || [];
			};

			const sortedSteps = computed(() => sortSteps());

			const tagsNetworks = computed(() => {
				const networks = [];
				if (steps.value) {
					steps.value
						.map((step) => step.tags)
						.forEach((step) => {
							step.forEach((tags) => {
								if (!networks.includes(tags.network_name)) {
									networks.push(tags.network_name);
								}
							});
						});
				}

				return networks;
			});
			// FUNCTIONS

			const toggleSettingsPanel = async () => {
				show_settings_panel.value = !show_settings_panel.value;
				if (show_settings_panel.value === true) {
					alerts.value = await store.getAlerts(scenarioID);
				}
			};

			const toggleImportPanel = () => {
				show_import_panel.value = !show_import_panel.value;
			};

			const toggleEventPanel = () => {
				show_event_panel.value = !show_event_panel.value;
			};

			const stepName = (stepID) => {
				return scenario.value.steps.filter((step) => step.id === stepID)[0]?.name;
			};

			const getAssertions = (type) => {
				const assertions = [];
				steps.value?.forEach((element) => {
					if (element[type]) {
						element[type].forEach((assertion) => {
							assertions.push(assertion);
						});
					}
				});
				return assertions;
			};

			// ACTIONS

			const updateStep = async (step) => {
				await store.updateStep(step);
			};

			const createStep = async () => {
				let last_step_number = 0;
				if (steps.value?.length > 0) {
					const last_step = steps.value.reduce((a, b) => (a.number > b.number ? a : b));
					last_step_number = last_step.number;
				}
				const body = {
					scenarioID: scenarioID,
					step: {
						name: 'New step',
						number: last_step_number + 1,
					},
				};
				await store.createStep(body);
			};

			const deleteStep = async (stepID) => {
				await store.deleteStep(stepID);
			};

			const updateScenarioOptional = async () => {
				const updated_scenario = {
					id: current_scenario.value.id,
					name: current_scenario.value.name,
					description: current_scenario.value.description,
					frequency: current_scenario.value.frequency,
					device: current_scenario.value.device,
					active: current_scenario.value.active,
				};
				await store.updateScenarioOptional(updated_scenario);
			};

			const deleteScenario = async () => {
				await store.deleteScenario(scenarioID);
				// Use router.push() instead of this.$router.push()
				router.push('/');
			};

			const runScenario = async () => {
				console.log('runScenario', scenario.value.id);
				isRunning.value = true;
				store.setLoading(true);

				await store.runScenario(scenario.value.id);
				store.setLoading(false);

				isRunning.value = false;
				router.push(`/scenarios/${current_scenario.value.id}/runs/${current_scenario.value.runs?.[current_scenario.value.runs.length - 1]?.id}`);
			};

			const getTags = async () => {
				isLoadingTags.value = true;
				console.log('getTags', scenario.value.id);
				await store.getTags(scenario.value.id);
				// getNetworks(); TODO - check this
				isLoadingTags.value = false;
			};

			const createAssertion = async () => {
				const body = {
					stepID: current_step_id.value,
					assertion: new_tag_assertion.value,
				};

				await store.createTagAssertion(body);

				new_tag_assertion.value = {
					type: '',
					network: '',
					key: '',
					value: '',
					match_any_request: true,
				};
			};

			const addAssertion = async (new_assertion) => {
				const body = {
					scenarioID: scenarioID,
					assertion: {
						key: new_assertion.name,
						type: new_assertion.type,
						values: new_assertion.values,
					},
				};
				await storeAssertion.createDataLayerAssertion(body);
			};

			const editAssertion = async (edited_assertion) => {
				let new_values;
				if (edited_assertion.values === '[]' || edited_assertion.values === '[""]' || edited_assertion.values === '' || !edited_assertion.values) new_values = null;
				if (typeof edited_assertion.values === 'string') new_values = [edited_assertion.values];
				const body = {
					scenarioID: scenarioID,
					assertion: {
						id: edited_assertion.id,
						key: edited_assertion.name,
						type: edited_assertion.type,
						values: new_values || edited_assertion.values,
					},
				};
				await storeAssertion.updateDataLayerAssertion(body);
			};

			const deleteTagAssertion = async (assertionID) => {
				await store.deleteTagAssertion(assertionID);
			};

			const deleteDataLayerAssertion = async (assertionID) => {
				await storeAssertion.deleteDataLayerAssertion(assertionID);
			};

			const importSteps = async () => {
				const body = {
					scenarioID: scenarioID,
					steps: import_steps.value,
				};
				await store.batchImport(body);
				toggleImportPanel(false);
				router.go();
			};

			const updateFrequency = async () => {
				const body = {
					scenarioID: scenarioID,
					frequency: current_scenario.value.frequency,
				};

				const { jobs } = await store.getSchedules(scenarioID);
				const current_job = jobs.find((item) => item.job_id == scenarioID);

				if (current_job) {
					await store.deleteSchedule(scenarioID);
				}

				await store.updateSchedule(body);
			};

			const removeFrequency = async () => {
				console.log('removeFrequency', scenarioID);

				if (scenarioID) {
					console.log('deleting Schedule');
					await store.deleteSchedule(scenarioID);
				}
			};

			const addMail = async () => {
				const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
				let alert = {};

				if (!newMail.value.match(emailRegex)) {
					mailError.value = 'Invalid email address';
				} else if (alerts.value.find((alert) => alert.mail === newMail.value)) {
					mailError.value = 'Email already in the list';
				} else {
					alert.mail = newMail.value;
					alert.frequency = current_scenario.value.max_fails;
					alert.scenario_id = scenarioID;
					await store.setAlert(alert);
					await store.getAlerts(scenarioID);
				}
			};

			const deleteAlerts = async (alertID) => {
				await store.deleteAlerts(alertID);
			};

			// ADD EVENT
			const addEventToScenario = async () => {
				isLoadingEvents.value = true;
				const payload = {
					scenario_id: scenario.value.id,
					event_id: selectedEvent.value.id,
				};
				await storeAssertion.addEventToScenario(payload);
				toggleEventPanel();
				selectedEvent.value = '';
				isLoadingEvents.value = false;
			};

			const removeEventFromScenario = async (eventID) => {
				const payload = {
					scenario_id: scenario.value.id,
					event_id: eventID,
				};
				await store.removeEventFromScenario(payload);
				selectedEvent.value = '';
			};

			return {
				lastLogs,
				scenario,
				steps,
				events,
				selectedEvent,
				toggleSettingsPanel,
				toggleImportPanel,
				toggleEventPanel,
				tabList,
				show_settings_panel,
				show_import_panel,
				show_event_panel,
				current_step_id,
				new_tag_assertion,
				new_datalayer_assertion,
				new_tag_assertion_step,
				new_datalayer_assertion_step,
				current_scenario,
				sorted_steps,
				isLoadingTags,
				isRunning,
				newMail,
				mailList,
				mailError,
				alerts,
				sortedSteps,
				updateStep,
				deleteStep,
				updateScenarioOptional,
				getTags,
				runScenario,
				deleteScenario,
				createAssertion,
				deleteTagAssertion,
				deleteDataLayerAssertion,
				stepName,
				getAssertions,
				createStep,
				tagsNetworks,
				importSteps,
				import_steps,
				updateFrequency,
				addMail,
				deleteAlerts,
				addEventToScenario,
				isLoading,
				removeEventFromScenario,
				handleTabChange,
				addAssertion,
				storeAssertion,
				editAssertion,
				isLoadingEvents,
				removeFrequency,
			};
		},
	};
</script>
