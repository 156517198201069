<template>
	<!-- STEP TITLE WRAPPER -->
	<div class="w-full flex justify-start items-center" @mouseover="toggleButtons(index)" @mouseout="toggleButtons(index)">
		<!-- EDIT STEP TITLE INPUT -->
		<div v-show="show_edit[index]" class="flex w-full">
			<input @keyup.enter="updateStep(current_step, index)" class="inline-flex items-center border border-gray-100 text-sm font-medium rounded-md p-2 border border-gray-300 text-gray-500" v-model="current_step.name" />

			<button @click="updateStep(current_step, index)" class="bg-green-50 hover:bg-green-100 hover:text-green-400 text-green-400 rounded-md p-2 ml-2">
				<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
					<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
				</svg>
			</button>
			<button @click="deleteStep(current_step.id, index)" class="bg-gray-100 hover:bg-red-100 hover:text-red-400 text-gray-400 rounded-md p-2 ml-2">
				<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
					<path
						fill-rule="evenodd"
						d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
						clip-rule="evenodd"
					/>
				</svg>
			</button>
		</div>
		<!-- STEP TITLE -->
		<p v-show="!show_edit[index]" class="inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-gray-500 bg-gray-200 border border-gray-300">
			<span class="circled mr-2 bg-gray-500 text-white">{{ index + 1 }} </span>{{ step.name }}
		</p>
		<!-- BUTTON EDIT STEP TITLE -->
		<div v-show="show_buttons[index] && !show_edit[index]" @click="toggleEdit(index)" class="bg-gray-200 hover:bg-gray-300 border border-gray-300 rounded-md p-2 cursor-pointer ml-4">
			<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
				<path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
			</svg>
		</div>
	</div>
</template>

<script>
	import { ref, watch } from 'vue';

	export default {
		name: 'StepHeader',
		props: {
			step: {
				type: Object,
				default: null,
			},
			index: {
				type: Number,
				default: 0,
			},
		},
		setup(props, { emit }) {
			const current_step = ref({});
			const show_edit = ref([false, false]);
			const show_buttons = ref([]);

			watch(
				() => props.step,
				(new_step) => {
					current_step.value = { ...new_step };
				}
			);

			const toggleEdit = (index) => {
				current_step.value = { ...props.step };
				show_edit.value[index] = !show_edit.value[index];
			};

			const toggleButtons = (index) => {
				show_buttons.value[index] = !show_buttons.value[index];
			};

			const updateStep = (step, index) => {
				toggleEdit(index);
				console.log('emit', step);
				emit('updateStep', step);
			};

			const deleteStep = (stepID, index) => {
				toggleEdit(index);
				emit('deleteStep', stepID);
			};

			return {
				toggleEdit,
				toggleButtons,
				updateStep,
				deleteStep,
				current_step,
				show_edit,
				show_buttons,
			};
		},
	};
</script>
