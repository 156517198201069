<template>
	<div
		ref="panelRef"
		class="fixed inset-y-0 right-0 max-w-full flex transform transition ease-in-out duration-300 sm:duration-500"
		:class="[isVisible ? 'translate-x-0' : 'translate-x-full', isLarge && isVisible ? 'w-full md:w-4/5' : 'sm:pl-10 w-full md:w-3/5']"
	>
		<div class="w-full">
			<div class="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
				<div class="px-4 sm:px-6">
					<div class="flex items-start justify-between">
						<div class="flex flex-wrap">
							<h2 class="w-full text-lg font-light text-gray-900 uppercase" id="slide-over-title">
								{{ title }}
							</h2>
							<div class="h-2px bg-main w-16 mt-2"></div>
						</div>

						<div class="ml-3 h-7 flex items-center">
							<button @click="$emit('togglePanel')" type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
								<span class="sr-only">Close panel</span>
								<svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
								</svg>
							</button>
						</div>
					</div>
				</div>
				<div class="mt-6 relative flex-1 px-4 sm:px-6">
					<slot />
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { onMounted, onBeforeUnmount, ref, defineProps, defineEmits } from 'vue';
	const emit = defineEmits(['togglePanel']);
	// Props
	const props = defineProps({
		title: {
			type: String,
			default: 'Panel',
		},
		isVisible: {
			type: Boolean,
			default: false,
		},
		isLarge: {
			type: Boolean,
			default: false,
		},
	});

	const panelRef = ref(null);

	const handleClickOutside = (event) => {
		if (props.isVisible && panelRef.value && !panelRef.value.contains(event.target)) {
			emit('togglePanel');
		}
	};

	onMounted(() => {
		document.addEventListener('click', handleClickOutside, true);
	});

	onBeforeUnmount(() => {
		document.removeEventListener('click', handleClickOutside, true);
	});
</script>
