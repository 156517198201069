import { defineStore } from 'pinia';
import axios from '@/axios';
// import axios from 'axios';

export const useAssertionStore = defineStore({
	id: 'assertions',
	state: () => ({
		assertions: [],
		keys: [],
		events: [],
	}),
	getters: {},
	actions: {
		// Assertions
		async getAssertions(scenarioID) {
			const { data } = await axios.get(`/scenario/${scenarioID}/read_assertions`);
			console.log('getScenarioAssertions', data);
			if (data.assertions_dl) {
				this.assertions = data.assertions_dl;
			}
		},
		async createDataLayerAssertion({ scenarioID, assertion }) {
			await axios.post(`/assertion_dl/?scenario_id=${scenarioID}`, assertion).then((response) => {
				this.assertions.push(response.data);
			});
		},
		async updateDataLayerAssertion({ scenarioID, assertion }) {
			await axios.put(`/assertion_dl/?scenario_id=${scenarioID}`, assertion).then((response) => {
				const assertion_index = this.assertions.findIndex((item) => item.id === assertion.id);
				this.assertions[assertion_index] = response.data;
			});
		},
		async deleteDataLayerAssertion(assertionID) {
			await axios.delete(`/assertion_dl/${assertionID}/`).then(() => {
				const assertion_index = this.assertions.findIndex((item) => item.id === assertionID);
				this.assertions.splice(assertion_index, 1);
			});
		},
		async addEventToScenario(payload) {
			await axios.put(`/event_to_scenario/?scenario_id=${payload.scenario_id}&event_id=${payload.event_id}`).then((response) => (this.assertions = response.data.assertions_dl));
		},
		// Events
		async getEvents() {
			await axios.get(`/events/`).then(({ data }) => {
				this.events = data;
			});
		},
		async createEvent(event) {
			await axios.post(`/events/`, event).then(() => {
				this.getEvents();
			});
		},
		async updateEvent(event) {
			console.log('updateEvent', event);
			await axios.put(`/events/${event.id}`, event).then(({ data }) => {
				let index = this.events.findIndex((e) => e.id == event.id);
				this.events[index] = data;
			});
		},
		async deleteEvent(event_id) {
			console.log('deleteEvent', event_id);
			await axios.delete(`/events/${event_id}`).then(() => {
				let index = this.events.findIndex((k) => k.id == event_id);
				this.events.splice(index, 1);
			});
		},
		// Keys
		async getKeys() {
			await axios.get(`/keys/`).then(({ data }) => {
				this.keys = data.sort((a, b) => a.name.localeCompare(b.name));
			});
		},
		async createKey(key) {
			await axios.post(`/keys/`, key).then((response) => {
				this.keys.push(response.data);
			});
		},
		async updateKey(key) {
			await axios.put(`/keys/${key.id}`, key).then(({ data }) => {
				let index = this.keys.findIndex((k) => k.id == key.id);
				this.keys[index] = data;
			});
		},
		async deleteKey(key_id) {
			await axios.delete(`/keys/${key_id}`).then(() => {
				let index = this.keys.findIndex((k) => k.id == key_id);
				this.keys.splice(index, 1);
			});
		},
	},
});
