<template>
	<div class="flex flex-wrap w-full p-4 bg-black">
		<p v-for="(log, index) in logs" :key="index" class="w-full py-1 text-white font-courier text-sm">
			{{ log || 'No log history' }}
		</p>
	</div>
</template>

<script>
	export default {
		name: 'ScenarioLogs',
		props: {
			logs: {
				type: Array,
				default: () => ['No log history'],
			},
		},
	};
</script>
