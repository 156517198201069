const network_icons = {
	'Google Analytics': 'https://cdn-icons-png.flaticon.com/512/732/732204.png',
	GA4: 'https://www.gstatic.com/analytics-suite/header/suite/v2/ic_analytics.svg',
	'Google Ads': 'https://redesunet.com/wp-content/uploads/2020/05/19463311701548141928-512.png',
	'Google Tag Manager': 'https://iconape.com/wp-content/png_logo_vector/google-tag-manager.png',
	'Google DoubleClick': 'https://assets.materialup.com/uploads/7ab238fe-b75c-46b7-80d0-25c42745a20f/preview',
	Bing: 'https://cdn-icons-png.flaticon.com/512/732/732186.png',
	Facebook: 'https://cdn-icons-png.flaticon.com/512/145/145802.png',
};

const networkIcon = (network) => {
	return network_icons[network];
};

export { networkIcon };
