const params_description = {
	ga4: {
		user_id: 'User Identifier',
		client_id: 'Client Identifier',
		el: 'Event Label',
		ea: 'Event Action',
		ec: 'Event Category',
		v: 'Version',
		tid: 'Tracking ID',
		gtm: 'Container ID',
		_p: 'App Instance ID',
		cid: 'Client ID',
		ul: 'User Language',
		sr: 'Screen Resolution',
		tt: 'User Timezone',
		ir: 'User Internal Referrer',
		_s: 'Session Number',
		dl: 'Location',
		sid: 'Session ID',
		dt: 'Page title',
		en: 'Event Name',
		'ep.page_language': 'Page language (custom event)',
		'ep.category_1': 'Category 1 (custom event)',
		'ep.category_2': 'Category 2 (custom event)',
		'ep.category_3': 'Category 3 (custom event)',
		'ep.website_source': 'Website source (custom event)',
		'ep.click_text': 'Click text (custom event)',
		url: 'URL',
	},
};

const prettyParam = (param, network) => {
	return params_description[network][param] || '-';
};

export { prettyParam };
