import { createRouter, createWebHistory } from 'vue-router';
import LoginView from '@/views/LoginView.vue';
import HomeView from '@/views/HomeView.vue';
import ScenarioView from '@/views/ScenarioView.vue';
import RunView from '@/views/RunView.vue';
import SettingsView from '@/views/SettingsView.vue';
import AssertionsView from '@/views/AssertionsView.vue';
import authGuard from '@/middlewares/authGuard';

const routes = [
	{
		path: '/',
		name: 'HomeView',
		component: HomeView,
		beforeEnter: authGuard,
	},
	{
		path: '/login',
		name: 'LoginView',
		component: LoginView,
		meta: {
			public: true,
		},
	},
	{
		path: '/settings',
		name: 'SettingsView',
		component: SettingsView,
		beforeEnter: authGuard,
	},
	{
		path: '/scenarios/:id',
		name: 'scenario',
		component: ScenarioView,
		props: (route) => ({ id: route.params.id }),
		beforeEnter: authGuard,
	},
	{
		path: '/scenarios/:sid/runs/:rid',
		name: 'run',
		component: RunView,
		props: (route) => ({ rid: route.params.rid, sid: route.params.sid }),
		beforeEnter: authGuard,
	},
	{
		path: '/assertions',
		name: 'AssertionsView',
		component: AssertionsView,
		beforeEnter: authGuard,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;
