import axios from '@/axios';
import qs from 'qs';
import { useUserStore } from '@/stores/users';

class AuthService {
	constructor(config) {
		this.config = config || {
			endpoints: {
				login: { url: '/token', method: 'post' },
				user: { url: '/me', method: 'get' },
			},
			token: {
				property: 'access_token',
				type: 'Bearer',
			},
		};
	}

	login(username, password) {
		const store = useUserStore();

		return axios
			.request({
				url: process.env.VUE_APP_API_URL + '/token',
				method: 'post',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				// convert to form data for backend
				data: qs.stringify({
					username,
					password,
				}),
			})
			.then((response) => {
				if (response.data.access_token) {
					store.setToken(response.data.access_token);
				}

				return response.data;
			});
	}

	getUserData() {
		return axios
			.request({
				url: process.env.VUE_APP_API_URL + '/me',
				method: 'get',
			})
			.then((response) => {
				return response.data;
			});
	}
}

export default new AuthService();
