<template>
	<div>
		<!-- Router:
		<router-link to="/">Home</router-link>
		<router-link to="/login">Login</router-link>-->
		<router-view></router-view>
	</div>
</template>

<script>
	import { onBeforeMount } from 'vue';
	import authService from '@/services/AuthService';
	import { useUserStore } from '@/stores/users';

	export default {
		name: 'App',
		components: {
			// HomeView,
		},
		setup() {
			const store = useUserStore();

			onBeforeMount(async () => {
				document.title = 'gvrn';
				const token = localStorage.getItem('token');

				if (!token) {
					store.removeToken();
				} else {
					store.setToken(token);
					await getUser();
				}
			});
			const getUser = async () => {
				await authService
					.getUserData()
					.then((response) => store.setUser(response))
					.catch((error) => {
						console.log(error);
					});
			};
			return {
				getUser,
			};
		},
	};
</script>
