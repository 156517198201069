<template>
	<div class="root min-h-screen flex flex-col justify-center classic-bg px-8 sm:px-0">
		<div class="sm:mx-auto sm:w-full sm:max-w-md">
			<div class="mx-auto w-full flex justify-center text-center">
				<img src="@/assets/classic-full.png" alt="classic-logo" class="h-12 sm:h-16 mt-2 sm:mt-0 px-1" />
			</div>
		</div>

		<div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md bg-white rounded-lg overflow-hidden shadow-lg">
			<div class="bg-white py-8 px-4 sm:px-10">
				<div v-if="login_error" class="flex justify-between items-center rounded-md border border-red-200 text-sm bg-red-100 text-red-600 px-2 py-2 mb-4 duration-100">
					<div>{{ login_error }}</div>
					<div @click="login_error = ''" class="cursor-pointer">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
							<path
								fill-rule="evenodd"
								d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
								clip-rule="evenodd"
							/>
						</svg>
					</div>
				</div>
				<form class="space-y-6" @submit.prevent="login">
					<div>
						<label for="email" class="block text-sm font-medium text-gray-700"> Email address </label>
						<div class="mt-1">
							<input
								id="email"
								name="email"
								v-model="email"
								type="email"
								autocomplete="email"
								required=""
								class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
							/>
						</div>
					</div>

					<div>
						<label for="password" class="block text-sm font-medium text-gray-700"> Password </label>
						<div class="mt-1">
							<input
								@keyup.enter="userLogin"
								id="password"
								v-model="password"
								name="password"
								type="password"
								autocomplete="current-password"
								required=""
								class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
							/>
						</div>
					</div>

					<div>
						<button type="submit" class="w-full flex justify-center py-2 px-4 button-gto text-white bg-custom-blue hover-bg-custom-blue-darker">Sign in</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
	import { ref } from 'vue';
	import { useUserStore } from '@/stores/users';

	import authService from '@/services/AuthService';
	import { useRouter } from 'vue-router';

	export default {
		name: 'LoginView',
		prop: {},
		setup() {
			const store = useUserStore();
			const router = useRouter();

			const email = ref('');
			const password = ref('');
			const login_error = ref('');

			const login = async () => {
				await authService
					.login(email.value, password.value)
					.then(() => {
						getUser();
					})
					.catch((error) => {
						console.log(error);
						login_error.value = 'Login error. Please check your credentials.';
					});
			};

			const getUser = async () => {
				await authService
					.getUserData()
					.then((response) => {
						store.setUser(response);
						router.push('/');
					})
					.catch((error) => {
						console.log(error);
					});
			};
			return {
				email,
				password,
				login_error,
				login,
				getUser,
			};
		},
	};
</script>
