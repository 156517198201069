<template>
	<div class="w-full">
		<div class="w-full border-b">
			<ul class="flex w-full">
				<li
					@click.prevent="setActiveTab(index)"
					class="py-4 -mb-px w-1/3 sm:w-auto text-center sm:text-left"
					:class="{
						'border-b-2 border-main': index === activeTab,
						'border-b-2 border-transparent hover:border-gray-300': index !== activeTab,
					}"
					v-for="(tab, index) in tabList"
					:key="index"
				>
					<label
						class="px-2 sm:px-10 py-4 cursor-pointer"
						:class="{
							'text-black': index === activeTab,
							'text-gray-500 hover:text-gray-600': index !== activeTab,
						}"
						:for="`${instance.uid.value}${index}`"
						v-text="tab"
					/>
					<input :id="`${instance.uid.value}${index}`" type="radio" :name="`${instance.uid.value}-tab`" :value="index" v-model="activeTab" class="hidden" />
				</li>
			</ul>
		</div>
		<template v-for="(tab, index) in tabList" :key="index">
			<div class="v-full mt-12" v-show="index === activeTab">
				<slot :name="`tabPanel-${index}`" />
			</div>
		</template>
	</div>
</template>
<script>
	import { ref, getCurrentInstance } from 'vue';

	export default {
		name: 'AppTabs',
		props: {
			tabList: {
				type: Array,
				required: true,
			},
		},
		setup(_, { emit }) {
			const instance = getCurrentInstance();
			let activeTab = ref(0);

			const setActiveTab = (tabIndex) => {
				console.log('setActiveTab');
				activeTab.value = tabIndex;
				emit('tabChange', tabIndex);
			};

			return {
				activeTab,
				instance,
				setActiveTab,
			};
		},
	};
</script>
