import axios from 'axios';
import { createPinia } from 'pinia';
import { useUserStore } from './stores/users';

// Set up axios with baseURL and headers
const axiosInstance = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	headers: {
		'Content-Type': 'application/json',
	},
});

const pinia = createPinia();
const userStore = useUserStore(pinia);

// Insert token in all queries
axiosInstance.interceptors.request.use(
	(config) => {
		// add the token to the headers if it exists
		if (pinia) {
			const token = pinia.state.value.users.token;
			if (token) {
				config.headers.Authorization = `Bearer ${token}`;
			}
		}
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

// Handle missing token
axiosInstance.interceptors.response.use(
	(response) => response,
	(error) => {
		const status = error.response ? error.response.status : null;
		console.log('status', status);
		if (status === 401 || status === 403) {
			userStore.removeToken();
			window.location = '/login';
		}
		return Promise.reject(error);
	}
);

export default axiosInstance;
