<template>
	<SidebarLayout>
		<div class="w-11/12 mx-auto">
			<!-- HEADING -->
			<Block class="mt-6 sm:mt-10" :border="true">
				<div class="flex align-center">
					<div class="flex items-center justify-center w-11 h-11 bg-main rounded">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" viewBox="0 0 20 20" fill="currentColor">
							<path
								d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z"
							/>
						</svg>
					</div>
					<div class="ml-3">
						<h1 class="w-full font-bold text-2xl m-0 leading-none">Settings</h1>
						<p class="w-full mt-1 leading-none">Customize your app</p>
					</div>
				</div>
			</Block>
			<!-- THEME SELECT -->
			<Block :border="true" :title="'Theme'" :subtitle="'Change the main colors'">
				<select v-model="current_theme" @change="updateTheme" class="button-gto text-white bg-main pl-6 pr-20 border border-main rounded rounded-md appearance-none custom-select w-full sm:w-auto">
					<option disabled value="">Select theme</option>
					<option :selected="theme == 'classic'" value="classic">Classic</option>
					<option :selected="theme == 'carglass'" value="carglass">Carglass</option>
				</select>
			</Block>
			<!-- LOGOUT -->
			<Block :border="true" :title="'Logout'">
				<div @click="logout" class="bg-main flex items-center justify-center rounded-md w-full sm:w-36 cursor-pointer">
					<div class="flex items-center sm:justify-center justify-center h-7 w-10 my-2 sm:mx-auto rounded text-white duration-200 hover:text-blue-500 hover:bg-opacity-50">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="white" viewBox="0 0 15.86 17">
							<path class="cls-1" d="M10,11.53a.93.93,0,0,1-.94-.94V2.45a.94.94,0,1,1,1.88,0v8.14A.93.93,0,0,1,10,11.53Z" transform="translate(-2.07 -1.5)" />
							<path class="cls-1" d="M10,18.5A7.94,7.94,0,0,1,6.53,3.43a.95.95,0,0,1,.83,1.7,6,6,0,1,0,5.28,0,.95.95,0,0,1,.83-1.7A7.94,7.94,0,0,1,10,18.5Z" transform="translate(-2.07 -1.5)" />
						</svg>
					</div>
				</div>
			</Block>
		</div>
	</SidebarLayout>
</template>

<script>
	import { ref, onMounted } from 'vue';

	import { useUserStore } from '@/stores/users';

	import router from '@/router';

	import Block from '@/components/Block';
	import SidebarLayout from '@/layouts/SidebarLayout.vue';

	export default {
		name: 'SettingsView',
		props: {
			theme: {
				type: String,
				default: 'classic',
			},
		},
		components: { Block, SidebarLayout },
		setup() {
			const store = useUserStore();

			const current_theme = ref('');
			const current_user = ref({});

			onMounted(() => {
				current_theme.value = store.getTeam?.theme || 'classic';
			});

			// FUNCTIONS
			const logout = () => {
				store.removeToken();
				router.push('/');
			};

			// change app's theme
			const updateTheme = async () => {
				current_user.value = store.getUser;
				console.log('current_user', current_user.value);

				const payload = {
					team_id: current_user.value.team_id,
					team_data: {
						name: 'Semetis', // TODO
						theme: current_theme.value,
					},
				};
				console.log(store);

				await store.updateTeam(payload);
			};

			return {
				current_theme,
				current_user,
				logout,
				updateTheme,
			};
		},
	};
</script>
