<template>
	<SidebarLayout>
		<!-- HEADING -->
		<ScenarioHead :scenario="scenario"></ScenarioHead>

		<!-- CONTENT -->
		<div class="w-11/12 2xl:w-10/12 mx-auto">
			<!-- RUN ASSERTIONS -->
			<Block title="Results" :border="true">
				<!-- <ScenarioTable :run="run" :loading="isLoading"></ScenarioTable> -->
				<p>Tag assertion(s)</p>
				<div class="w-full mt-4 border border-2 border-gray-50 rounded rounded-md overflow-hidden" id="tag-assertion-result-panel">
					<div class="flex justify-between items-center bg-gray-50" v-show="run?.results.assertions_results.filter((item) => item.assertion_type === 'tag').length">
						<div class="py-2 text-gray-400 uppercase text-xs text-center w-1/4">step</div>
						<div class="py-2 text-gray-400 uppercase text-xs text-center w-1/4">network</div>
						<div class="py-2 text-gray-400 uppercase text-xs text-center w-1/4">value</div>
					</div>
					<div v-for="(assertion, assertion_index) in run?.results.assertions_results.filter((item) => item.assertion_type === 'tag')" :key="assertion_index">
						<div class="flex justify-between items-center" :class="assertion.result === 'Pass' ? 'bg-custom-green-opacity' : 'bg-custom-red-opacity'">
							<div class="w-1/4 py-2 text-gray-600 text-center break-none" :title="assertion.step">{{ assertion.step }}</div>
							<div class="w-1/4 py-2 text-gray-600 text-center" :title="assertion.assertion.network">{{ assertion.assertion.network }}</div>
							<div class="w-1/4 py-2 text-gray-600 text-center break-none" :title="assertion.value">{{ assertion.value }}</div>
						</div>
					</div>
					<div v-show="!run?.results.assertions_results?.length" class="h-full flex justify-center items-center bg-gray-50 text-gray-400 uppercase text-xs text-center py-4">No tag assertion</div>
				</div>
				<p class="mt-6">DataLayer assertion(s)</p>
				<div class="w-full mt-4 border border-2 border-gray-50 rounded rounded-md overflow-hidden" id="dl-assertion-result-panel">
					<div class="flex justify-between items-center bg-gray-50" v-show="run?.results.assertions_results.filter((item) => item.assertion_type === 'dl').length">
						<div class="py-2 text-gray-400 uppercase text-xs text-center w-1/4">step</div>
						<div class="py-2 text-gray-400 uppercase text-xs text-center w-1/4">event</div>
						<div class="py-2 text-gray-400 uppercase text-xs text-center w-1/4">key</div>
						<div class="py-2 text-gray-400 uppercase text-xs text-center w-1/4">operator</div>
						<div class="py-2 text-gray-400 uppercase text-xs text-center w-1/4">expected value</div>
						<div class="py-2 text-gray-400 uppercase text-xs text-center w-1/4">value</div>
					</div>
					<div v-for="(assertion, assertion_index) in run?.results.assertions_results.filter((item) => item.assertion_type === 'dl')" :key="assertion_index">
						<div class="flex justify-between items-center" :class="assertion.result === 'Pass' ? 'bg-custom-green-opacity' : 'bg-custom-red-opacity'">
							<div class="w-1/4 py-2 text-gray-600 text-center break-none" :title="assertion.step">{{ assertion.step }}</div>
							<div class="w-1/4 py-2 text-gray-600 text-center break-none" :title="assertion.event">{{ assertion.event }}</div>
							<div class="w-1/4 py-2 text-gray-600 text-center break-none" :title="assertion.key">{{ assertion.key }}</div>
							<div class="w-1/4 py-2 text-gray-600 text-center" :title="assertion.type">{{ assertion.type }}</div>
							<div class="w-1/4 py-2 text-gray-600 text-center" :title="assertion.expected_value">{{ assertion.expected_value.join(' | ') || '-' }}</div>
							<div class="w-1/4 py-2 text-gray-600 text-center" :title="assertion.value">{{ JSON.stringify(assertion.value) || '-' }}</div>
						</div>
					</div>
					<div v-show="!run?.results.assertions_results.filter((item) => item.assertion_type === 'dl').length" class="h-full flex justify-center items-center bg-gray-50 text-gray-400 uppercase text-xs text-center py-4">No dataLayer assertion</div>
				</div>
			</Block>

			<!-- RUN LOGS -->
			<Block title="Logs" :border="true">
				<ScenarioLogs :logs="run?.results?.logs"></ScenarioLogs>
			</Block>

			<!-- RUN COMMENTS -->
			<Block title="Scenario comments" :border="true">
				<ScenarioComments :scenario_id="scenario.id" :comments="scenario.comments"></ScenarioComments>
			</Block>
		</div>
	</SidebarLayout>
</template>

<script>
	import { computed, onMounted } from 'vue';
	import { useRoute } from 'vue-router';

	import { useScenarioStore } from '@/stores/scenarios';

	import ScenarioHead from '@/components/ScenarioHead.vue';
	import Block from '@/components/Block.vue';
	import ScenarioLogs from '@/components/ScenarioLogs.vue';
	// import ScenarioTable from '@/components/ScenarioTable.vue';
	import ScenarioComments from '@/components/ScenarioComments.vue';

	import SidebarLayout from '@/layouts/SidebarLayout.vue';

	export default {
		name: 'RunView',
		props: {
			rid: {
				type: String,
				required: true,
			},
			sid: {
				type: String,
				required: true,
			},
		},
		emits: [],
		components: { SidebarLayout, ScenarioComments, ScenarioHead, Block, ScenarioLogs },

		setup() {
			const store = useScenarioStore();
			const route = useRoute();

			const isLoading = computed(() => store.loader) || [];
			const scenario = computed(() => store.scenario) || [];
			const runs = computed(() => store.scenario.runs) || [];
			const run = computed(() => runs.value?.find((run) => run.id == route.params.rid)) || [];

			onMounted(async () => {
				store.setLoading(true);
				await store.getScenario(route.params.sid);
				store.setLoading(false);
			});

			return {
				isLoading,
				scenario,
				run,
			};
		},
	};
</script>
