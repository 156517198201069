<template>
	<div>
		<Block class="sm:mt-10 w-11/12 2xl:w-10/12 mx-auto" :border="true">
			<div class="flex align-center w-full flex-wrap justify-between">
				<!-- PAGE TYPE & TITLE SQUELETON -->
				<div v-if="!scenarioLoaded" class="flex w-2/6">
					<div class="flex items-center justify-center w-12 h-12 rounded bg-gray-100 rounded-sm animate-pulse"></div>
					<div class="flex flex-col justify-between w-3/5 ml-3">
						<div class="w-full h-5 bg-gray-100 rounded-sm animate-pulse"></div>
						<div class="w-full h-5 bg-gray-100 rounded-sm animate-pulse"></div>
					</div>
				</div>
				<!-- PAGE TYPE & TITLE -->
				<div v-else class="flex items-center w-full xl:w-2/5">
					<div class="flex items-center justify-center w-11 h-11 rounded" :class="statusColor">
						<svg v-if="is_edit_page" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" viewBox="0 0 20 20" fill="currentColor">
							<path
								d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z"
							/>
						</svg>
						<svg v-else xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor">
							<path fill-rule="evenodd" d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z" clip-rule="evenodd" />
						</svg>
					</div>
					<div class="ml-3 max-w-[350px] 2xl:max-w-[400px]">
						<!-- TODO insert max char length for scenario title -->
						<h1 class="w-full font-semibold text-md m-0 leading-5">{{ current_scenario?.name }}</h1>
						<!-- <p class="w-full mt-1 leading-none">{{ is_edit_page ? 'Scenario ' : 'Run ' }} overview</p> -->
					</div>
				</div>

				<!-- DATES & TOGGLES SQUELETON -->
				<div v-if="!scenarioLoaded" class="flex flex-wrap w-3/6">
					<div class="flex flex-wrap items-center w-1/2 pr-4">
						<div class="w-full h-4 bg-gray-100 rounded-sm"></div>
						<div class="w-full h-4 mt-1 bg-gray-100 rounded-sm"></div>
						<div class="w-full h-4 mt-1 bg-gray-100 rounded-sm"></div>
					</div>
					<div class="w-1/2">
						<div class="w-24 h-6 bg-gray-100 rounded-sm"></div>
						<div class="w-24 h-6 mt-2 bg-gray-100 rounded-sm"></div>
					</div>
				</div>

				<!-- DATES & TOGGLES -->
				<div v-else class="flex flex-wrap md:w-full mt-6 xl:mt-0 lg:w-2/3 xl:w-2/5">
					<!-- DATES -->
					<div class="flex flex-wrap items-center w-full sm:w-1/2">
						<div class="w-full text-xs text-gray-500">Created on {{ prettyDate(current_scenario?.created_on) }}</div>
						<div class="w-full text-xs text-gray-500">Edited on {{ prettyDate(current_scenario?.updated_on) }}</div>
						<div v-if="!current_scenario?.runs?.length || !lastRunPassed" @click="$router.push({ path: `/scenarios/${current_scenario.id}/runs/${lastRun.id}` })" class="w-full text-xs text-red-600 hover:underline cursor-pointer">
							Failed on {{ prettyDate(current_scenario.last_failed_on) }}
						</div>
					</div>

					<!-- TOGGLES -->
					<div class="w-full mt-6 sm:mt-0 sm:w-1/2">
						<UIToggle class="uppercase" :status="current_scenario?.work_in_progress" :input="'toggleWIP'" @toggleWIP="toggleWIP" :loading="isWIPloading">Work in progress</UIToggle>
						<UIToggle class="uppercase mt-2" :status="current_scenario?.active" :input="'toggleStatus'" @toggleStatus="toggleStatus" :loading="isStatusloading">Activated</UIToggle>
					</div>
				</div>

				<!-- BUTTON SQUELETON -->
				<div v-if="!scenarioLoaded" class="flex flex-wrap w-1/6 items-center justify-end">
					<div class="flex flex-wrap items-center w-32 h-12 bg-gray-100 rounded-md animate-pulse"></div>
				</div>
				<!-- BUTTON -->
				<div v-else class="flex flex-wrap items-center justify-start lg:justify-end md:w-full mt-6 xl:mt-0 lg:w-1/3 xl:w-1/5">
					<div v-if="is_edit_page">
						<button
							type="button"
							@click="$emit('run')"
							class="w-full sm:w-auto justify-center sm:justify-start button-gto hover:bg-custom-blue-darker mr-2"
							:disabled="isLoading"
							:class="isLoading ? 'cursor-not-allowed bg-gray-100 text-gray-300 hover:bg-gray-100' : 'cursor-pointer bg-main text-white'"
						>
							<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 -ml-2" :class="isLoading ? ' text-gray-300' : ' text-white'" viewBox="0 0 20 20" fill="currentColor">
								<path fill-rule="evenodd" d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z" clip-rule="evenodd" />
							</svg>
							Run
						</button>
						<button @click="$emit('togglePanel')" type="button" class="button-gto bg-gray-100 rounded-sm hover:bg-gray-200 border border-gray-300 text-gray-500 xl:mr-4 xl:mb-0 lg:mr-2 lg:mb-0 sm:mr-0 sm:mb-2">
							<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 -ml-2 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
								<path
									fill-rule="evenodd"
									d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
									clip-rule="evenodd"
								/>
							</svg>
							Settings
						</button>
					</div>
					<button v-else @click="$router.push({ path: `/scenarios/${current_scenario.id}` })" type="button" class="button-gto text-white bg-custom-blue hover:bg-custom-blue-darker border border-custom-blue">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 xl:mr-2 xl:-ml-2" viewBox="0 0 20 20" fill="currentColor">
							<path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
						</svg>
						Edit Scenario
					</button>
				</div>
			</div>
		</Block>
	</div>
</template>

<script>
	import { ref, computed, watch } from 'vue';

	import { useScenarioStore } from '@/stores/scenarios';

	import Block from '@/components/Block.vue';
	import UIToggle from '@/components/UIToggle.vue';

	import { prettyDate } from '../utils/date.js';

	export default {
		name: 'ScenarioHead',
		components: { Block, UIToggle },
		props: {
			scenario: {
				type: Object,
				default: () => {},
			},
			is_edit_page: {
				type: Boolean,
				default: false,
			},
		},
		setup(props) {
			const store = useScenarioStore();
			const isLoading = computed(() => store.loader) || false;

			const isWIPloading = ref(false);
			const isStatusloading = ref(false);

			const current_scenario = ref({});

			// onBeforeMount(async () => {
			// 	console.log('props.scenario', props.scenario);
			// 	current_scenario.value = { ...props.scenario };
			// });

			watch(
				() => props.scenario,
				(new_scenario) => {
					current_scenario.value = { ...new_scenario };
				}
			);

			// COMPUTED
			const scenarioLoaded = computed(() => {
				return !!current_scenario.value?.runs;
			});

			const lastRun = computed(() => {
				return current_scenario.value?.runs?.[current_scenario.value?.runs?.length - 1];
			});

			const lastRunPassed = computed(() => {
				return !!lastRun.value?.status;
			});

			const statusColor = computed(() => {
				if (current_scenario.value?.work_in_progress) return 'bg-custom-orange';
				else if (!current_scenario.value?.runs?.length) return 'bg-gray-300';
				else if (lastRunPassed.value) return 'bg-custom-green';
				else if (!lastRunPassed.value) return 'bg-custom-red';
				else return 'bg-main';
			});

			// ACTIONS

			const updateScenario = async (scenario) => {
				const updated_scenario = { ...scenario };
				// store.setLoading(true);
				await store.updateScenario(updated_scenario);
				store.setLoading(false);
			};

			const updateFrequency = async () => {
				const scenario_updated = { ...current_scenario.value };
				scenario_updated.frequency = current_scenario.value.frequency;

				const body = {
					scenarioID: current_scenario.value.id,
					frequency: current_scenario.value.frequency,
				};
				// store.setLoading(true);
				await updateScenario(scenario_updated);
				await updateSchedule(body);
				store.setLoading(false);
			};

			const updateSchedule = async (body) => {
				// store.setLoading(true);
				await deleteFrequency();
				await store.updateSchedule(body);
				store.setLoading(false);
			};

			const deleteFrequency = async () => {
				const { jobs } = await store.getSchedules();
				const current_job = jobs.find((item) => item.job_id == props.scenario.id);
				if (current_job) {
					store.setLoading(true);
					await store.deleteSchedule(props.scenario.id);
					store.setLoading(false);
				}
			};

			const toggleWIP = async (value) => {
				const updated_scenario = {
					id: current_scenario.value.id,
					work_in_progress: value,
				};
				isWIPloading.value = true;
				await store.updateScenarioOptional(updated_scenario);
				isWIPloading.value = false;
			};

			const toggleStatus = async (value) => {
				const updated_scenario = {
					id: current_scenario.value.id,
					active: value,
				};

				isStatusloading.value = true;
				await store.updateScenarioOptional(updated_scenario);
				isStatusloading.value = false;
			};

			return {
				prettyDate,
				updateScenario,
				updateFrequency,
				deleteFrequency,
				toggleWIP,
				toggleStatus,
				lastRunPassed,
				statusColor,
				current_scenario,
				isLoading,
				isWIPloading,
				isStatusloading,
				lastRun,
				scenarioLoaded,
			};
		},
	};
</script>
