<template>
	<div class="w-full">
		<div v-if="isSelected" class="flex flex-wrap w-full">
			<div class="w-full mt-4 border border-2 border-gray-50 rounded rounded-md overflow-hidden">
				<div class="flex justify-between items-center">
					<div class="py-2 px-4 text-gray-400 uppercase text-xs text-left bg-gray-50 w-2/12 pl-10">network</div>
					<div class="py-2 px-4 text-gray-400 uppercase text-xs text-left bg-gray-50 w-2/12 pl-10">Google tag ID</div>
					<div class="py-2 px-4 text-gray-400 uppercase text-xs text-left bg-gray-50 w-2/12 pl-10">event name</div>
					<div class="py-2 px-4 text-gray-400 uppercase text-xs text-left bg-gray-50 w-4/12">url</div>
					<div class="py-2 px-4 text-gray-400 uppercase text-xs text-left bg-gray-50 w-2/12">tracked</div>
				</div>
				<div v-if="!tags.length" class="w-full flex items-center justify-center border-gray-50 text-gray-400 rounded-md py-6 text-sm">No tags for this step</div>
				<div v-for="tag in tags.sort((a, b) => a.id - b.id)" :key="tag.id" class="my-2">
					<div class="flex items-center">
						<div class="w-2/12 py-2 px-4 text-gray-600 text-center break-none flex items-center" :title="''">
							<img class="h-auto w-6 mr-3" :src="networkIcon(tag.network_name)" :alt="tag.network_name + ' icon'" />
							<div>{{ tag.network_name }}</div>
						</div>
						<div class="w-2/12 py-2 px-4 text-gray-600 text-left" :title="''">{{ tag.params?.['tid']?.[0] || '-' }}</div>
						<div class="w-2/12 py-2 px-4 text-gray-600 text-left break-none" :title="''">{{ tag.params?.['en']?.join(', ') || '-' }}</div>
						<div class="w-4/12 py-2 px-4 text-gray-600 text-left break-none" :title="''">{{ tag.url }}</div>
						<div class="w-2/12 px-4">
							<label class="relative inline-flex items-center cursor-pointer">
								<input type="checkbox" v-model="tag.tracked" @change="trackTag(tag)" class="sr-only peer" />
								<div
									class="w-9 h-5 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-custom-green"
								></div>
							</label>
						</div>
					</div>
					<!-- <div class="w-full mt-4 border border-2 border-gray-50 rounded rounded-md overflow-hidden">
						<div class="flex justify-between items-center">
							<div class="py-2 text-gray-400 uppercase text-xs text-center bg-gray-50 w-1/4">name</div>
							<div class="py-2 text-gray-400 uppercase text-xs text-center bg-gray-50 w-1/4">key</div>
							<div class="py-2 text-gray-400 uppercase text-xs text-center bg-gray-50 w-1/4">value</div>
						</div>
						<div v-for="(param_value, param_key, param_index) in orderedByKey(tag.params)" :key="param_index" class="my-2">
							<div class="flex justify-between items-center">
								<div class="w-1/4 py-2 text-gray-600 text-center" :title="''">{{ prettyParam(param_key, 'ga4') }}</div>
								<div class="w-1/4 py-2 text-gray-600 text-center" :title="''">{{ param_key }}</div>
								<div class="w-1/4 py-2 text-gray-600 text-center break-none" :title="''">{{ param_value?.[0] }}</div>
							</div>
						</div>
					</div> -->
				</div>
			</div>
			<!-- <div v-else class="w-full">
				<table class="w-full mt-8 rounded">
					<tr v-for="(tag, index) in currentStep?.tags" :key="tag.id">
                        <d
						<table class="w-full">
							<ScenarioTagsTableHead @setTag="setCurrentTag" :tag="tag" :index="index" :visibleTag="visibleTag" :class="index % 2 === 0 ? 'bg-gray-50' : ''" class="cursor-pointer hover:bg-gray-100"></ScenarioTagsTableHead>
							<ScenarioTagsTableBody :tagsNetworks="tagsNetworks" :steps="steps" :stepID="current" :tag="tag" :class="visibleTag === index ? '' : 'hidden'" @tagClick="addAssertion"></ScenarioTagsTableBody>
						</table>
					</tr>
				</table>
			</div> -->
		</div>
	</div>
</template>

<script>
	import { ref, computed } from 'vue';

	import { useScenarioStore } from '@/stores/scenarios';
	import { useAssertionStore } from '@/stores/assertions';

	import { prettyParam } from '@/utils/datalayer_params';
	import { networkIcon } from '@/utils/platform_icons';

	export default {
		name: 'TagsTable',
		emits: ['visibleTag', 'tagClick'],
		props: {
			current: {
				type: [Number, String],
			},
			isSelected: {
				type: Boolean,
				default: false,
			},
			// contains only the network title, check if it can be removed, steps.tags contains all info already
			tagsNetworks: {
				type: Array,
				default: () => [],
			},
		},
		setup(props) {
			const store = useScenarioStore();
			const storeAssertion = useAssertionStore();

			const steps = computed(() => store.scenario.steps) || [];

			const visibleTag = ref(null);

			const newTagAssertion = ref();
			const newTagAssertionStep = ref();
			const newDatalayerAssertion = ref();
			const newDatalayerAssertionStep = ref();

			// COMPUTED

			const currentStep = computed(() => {
				if (steps.value?.length > 0) {
					return steps.value.filter((step) => step.id === props.current)[0];
				}
				return [];
			});

			const tags = computed(() => {
				return currentStep.value.tags;
			});

			// FUNCTIONS

			const setCurrentTag = (tag) => {
				visibleTag.value === tag ? (visibleTag.value = null) : (visibleTag.value = tag);
			};

			const orderedByKey = (unordered) => {
				return Object.keys(unordered)
					.sort()
					.reduce((obj, key) => {
						obj[key] = unordered[key];
						return obj;
					}, {});
			};

			const trackTag = async (tag) => {
				console.log('tag', tag);
				console.log('currentStep', currentStep);
				const tags = currentStep.value.tags;
				const currentTag = tags.find((item) => item.id === tag.id);
				console.log('currentTag.tracked', currentTag.tracked);
				console.log('tag.tracked', tag.tracked);
				currentTag.tracked = tag.tracked;
				// update step in store
				await store.updateStep(currentStep.value);
			};

			// ACTIONS

			const addAssertion = async (type) => {
				let assertion, assertion_type, new_assertion_step;
				if (type === 'tags') {
					assertion = newTagAssertion.value;
					assertion_type = 'createTagAssertion';
					new_assertion_step = newTagAssertionStep.value;
				} else {
					assertion = newDatalayerAssertion.value;
					assertion_type = 'createDataLayerAssertion';
					new_assertion_step = newDatalayerAssertionStep.value;
				}
				const isAssertionFilled = Object.values(assertion).every((value) => {
					return value !== '';
				});
				if (isAssertionFilled) {
					assertion.match_any_request = true;
					const body = {
						step_id: new_assertion_step.id,
						assertion: assertion,
					};
					if (assertion_type == 'createTagAssertion') await store.createTagAssertion(body);
					if (assertion_type == 'createDataLayerAssertion') await storeAssertion.createDataLayerAssertion(body);

					if (type === 'tags') {
						newTagAssertion.value = {
							type: '',
							network: '',
							key: '',
							value: '',
							match_any_request: true,
						};
					} else {
						newDatalayerAssertion.value = {
							type: '',
							key: '',
							value: '',
							match_any_request: true,
						};
					}
				}
			};

			return {
				steps,
				visibleTag,
				currentStep,
				addAssertion,
				setCurrentTag,
				prettyParam,
				orderedByKey,
				networkIcon,
				trackTag,
				tags,
			};
		},
	};
</script>
