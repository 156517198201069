<template>
	<div class="w-full">
		<label class="w-full text-left">
			<textarea class="form-textarea mt-1 w-full border rounded-md p-4 focus:outline-none focus:border-gray-300" rows="3" v-model="new_comment" placeholder="Type a comment here."></textarea>
		</label>
		<div class="flex justify-end mt-2">
			<button type="button" @click="addComment" class="button-gto bg-custom-blue hover:bg-custom-blue-darker border border-custom-blue text-white">
				<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 xl:mr-2 xl:-ml-2 text-white" viewBox="0 0 20 20" fill="currentColor">
					<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd" />
				</svg>
				<div class="hidden xl:block">Add comment</div>
			</button>
		</div>
		<div v-for="(comment, index) in orderedComments" :key="index">
			<div class="my-4">
				<div>
					<span class="text-md font-medium">{{ comment.user.first_name }} {{ comment.user.name }}:</span>
					<span class="text-sm text-gray-400">{{ getCommentDate(comment.date_created) }}</span>
				</div>
				<div class="italic text-gray-600">{{ comment.content }}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { ref, computed } from 'vue';

	import { useScenarioStore } from '@/stores/scenarios';

	import { prettyDate } from '../utils/date.js';

	export default {
		name: 'ScenarioComments',
		props: {
			comments: {
				type: Array,
				default: () => [],
			},
			scenario_id: {
				type: Number,
				default: null,
			},
		},
		setup(props) {
			const store = useScenarioStore();

			const new_comment = ref('');

			// METHODS

			const orderedComments = computed(() => {
				return [...props.comments].sort((a, b) => (a.date > b.date ? -1 : a.date < b.date ? 1 : 0));
			});

			// FUNCTIONS

			const getCommentDate = (timestamp) => {
				const new_date = prettyDate(timestamp);
				return new_date;
			};

			// ACTIONS

			const addComment = async () => {
				const body = {
					scenarioID: props.scenario_id,
					comment: {
						content: new_comment.value,
					},
				};
				await store.addComment(body);
				new_comment.value = '';
			};

			return {
				new_comment,
				getCommentDate,
				addComment,
				orderedComments,
			};
		},
	};
</script>
