const prettyDate = (date) => {
  if (date) {
    const split_all = date.split('T')
    const split_date = split_all[0].split('-')
    const split_time = split_all[1].split('.')[0].split(':').slice(0, 2).join(':')
    return `${split_date[2]}/${split_date[1]}/${split_date[0]} at ${split_time}`
  }
}

export { prettyDate }
