<template>
	<div class="my-5">
		<table class="w-full table-auto border">
			<thead class="border-b">
				<tr class="hidden sm:table-row">
					<th class="text-left py-6 pl-6 font-light">ACTION</th>
					<th class="text-left py-6 pl-6 font-light">VALUE</th>
					<th class="text-left py-6 pl-6 font-light">ELEMENT</th>
					<th class="text-left py-6 px-3 font-light">EDIT</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(action, index) in sortedActions" :key="index" class="hidden sm:table-row">
					<td class="py-6 pl-6 w-2/12">{{ action.action }}</td>
					<td class="py-6 px-3 w-4/12" :class="action.value ? '' : 'bg-gray-100'">
						<div v-if="!showButtons[index]" class="pl-3 border border-transparent">{{ action.value }}</div>
						<input v-else v-show="action.value" v-model="action.value" @keyup.enter="saveEditAction(action)" class="w-full rounded-md border border-gray-100 py-3 pl-3" />
					</td>
					<td class="py-6 pl-3 w-6/12" :class="action.element ? '' : 'bg-gray-100'">
						<div v-if="!showButtons[index]" class="pl-3 border border-transparent">{{ action.element }}</div>
						<input v-else v-show="action.element" v-model="action.element" @keyup.enter="saveEditAction(action)" class="w-full rounded-md border border-gray-100 py-3 pl-3" />
					</td>

					<td class="flex justify-center items-center p-3">
						<div class="flex-col">
							<!-- MOVE UP -->
							<div @click="index > 0 ? moveAction(action, 'up') : null" class="rounded-md p-2 my-1" :class="index > 0 ? 'bg-gray-100 hover:bg-gray-200 cursor-pointer' : 'bg-gray-50'">
								<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
									<path fill-rule="evenodd" d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />
								</svg>
							</div>
							<!-- MOVE DOWN -->
							<div @click="index < sortedActions.length - 1 ? moveAction(action, 'down') : null" class="rounded-md p-2 my-1" :class="index < sortedActions.length - 1 ? 'bg-gray-100 hover:bg-gray-200 cursor-pointer' : 'bg-gray-50'">
								<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
									<path fill-rule="evenodd" d="M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z" clip-rule="evenodd" />
								</svg>
							</div>
						</div>
						<div class="flex-col ml-1">
							<!-- EDIT -->
							<div v-if="!showButtons[index]" @click="editAction(action, index)" class="bg-blue-100 hover:bg-blue-200 rounded-md p-2 cursor-pointer my-1">
								<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-blue-400" viewBox="0 0 20 20" fill="currentColor">
									<path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
								</svg>
							</div>
							<div v-else @click="saveEditAction(action)" class="bg-green-100 hover:bg-green-200 rounded-md p-2 cursor-pointer my-1">
								<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
									<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
								</svg>
							</div>
							<!-- DELETE -->
							<div @click="removeAction(action)" class="bg-red-100 hover:bg-red-200 rounded-md p-2 cursor-pointer my-1">
								<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
									<path
										fill-rule="evenodd"
										d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
										clip-rule="evenodd"
									/>
								</svg>
							</div>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
		<div class="flex w-full flex-wrap sm:flex-nowrap my-6">
			<div class="w-full sm:w-2/12 flex justify-center min-w-max">
				<select @change="switchActionType($event)" v-model="action.action" class="button-gto text-white bg-custom-blue w-full pl-6 pr-20 mx-auto border rounded rounded-md appearance-none custom-select">
					<option selected disabled>Select action</option>
					<option value="click">Click</option>
					<!-- <option value="datepicker">Datepicker</option> -->
					<option value="goto">Go to</option>
					<option value="press">Press</option>
					<option value="select">Select</option>
					<option value="input">Input</option>
					<option value="wait">Wait</option>
					<option value="page_load">Page load</option>
				</select>
			</div>

			<div class="w-full sm:w-4/12 mt-2 sm:mt-0 sm:pl-4 sm:pr-2">
				<input @keyup.enter="addAction(action)" type="text" :disabled="this.isValueDisabled ? true : false" class="input-gto pl-6-i w-full" placeholder="Enter value" v-model="action.value" />
			</div>
			<div class="w-full sm:w-5/12 my-2 sm:my-0 sm:pl-2 sm:pr-4">
				<input @keyup.enter="addAction(action)" type="text" :disabled="this.isElementDisabled ? true : false" class="input-gto pl-6-i w-full" placeholder="Enter element" v-model="action.element" />
			</div>
			<div class="w-full sm:w-1/12">
				<button @click="addAction(action)" type="button" class="button-gto text-white bg-custom-blue button-gto--custom w-full flex justify-center">
					<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 xl:mr-2 xl:-ml-2" viewBox="0 0 20 20" fill="currentColor">
						<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd" />
					</svg>
					<span class="hidden xl:block">Add</span>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
	import { ref, computed, onBeforeMount, watch } from 'vue';

	import { useScenarioStore } from '@/stores/scenarios';

	export default {
		name: 'ActionsTable',
		props: {
			step: {
				type: Object,
			},
		},

		setup(props) {
			const store = useScenarioStore();

			const action = ref({
				action: 'Select action',
				value: '',
				element: '',
			});
			const currentStep = ref();

			onBeforeMount(async () => {
				currentStep.value = { ...props.step };
			});

			watch(
				() => props.step,
				(new_step) => {
					currentStep.value = { ...new_step };
				}
			);

			const currentAction = ref({
				action: 'Select action',
				value: '',
				element: '',
			});
			const showButtons = ref([]);
			const isValueDisabled = ref(false);
			const isElementDisabled = ref(false);

			const sortedActions = computed(() => [...currentStep.value?.actions].sort((a, b) => (a.number > b.number ? 1 : -1)));

			const switchActionType = (event) => {
				switch (event.target.value) {
					case 'input':
						isValueDisabled.value = false;
						isElementDisabled.value = false;
						break;
					case 'goto':
						isValueDisabled.value = false;
						isElementDisabled.value = true;
						break;
					case 'datepicker':
						isValueDisabled.value = true;
						isElementDisabled.value = false;
						break;
					case 'wait':
						isValueDisabled.value = false;
						isElementDisabled.value = true;
						break;
					case 'select':
						isValueDisabled.value = false;
						isElementDisabled.value = false;
						break;
					case 'press':
						isValueDisabled.value = false;
						isElementDisabled.value = true;
						break;
					case 'page_load':
						isValueDisabled.value = false;
						isElementDisabled.value = true;
						break;
					default:
						isValueDisabled.value = true;
						isElementDisabled.value = false;
				}
				action.value.value = '';
				action.value.element = '';
			};

			const addAction = async (action) => {
				const step = currentStep.value;
				let last_action = { number: 0 };
				if (step.actions.length > 0) {
					last_action = step.actions.reduce((a, b) => (a.number > b.number ? a : b));
				}
				if ((action.value || action.element) && action.action !== 'Select action') {
					const new_action = {
						action: action.action,
						value: action.value,
						element: action.element,
						number: last_action.number + 1,
					};
					const body = {
						stepID: step.id,
						action: new_action,
					};
					await store.createAction(body);
				}
			};

			const removeAction = async (action) => {
				// Remove action
				await store.deleteAction(action.id);
				// Update all positions to fit indexes
				resetActionsPositions(action.number);
			};

			const editAction = (action, index) => {
				currentAction.value = { ...action };
				showButtons.value = [];
				showButtons.value[index] = true;
			};

			const saveEditAction = async (action) => {
				// Update action if any change
				if (action.element != currentAction.value.element || action.value != currentAction.value.value) {
					await store.updateAction(action);
				}
				showButtons.value = [];
			};

			const resetActionsPositions = async (position) => {
				sortedActions.value.forEach((action, index) => {
					// If action's position is > position
					if (action.number > position) {
						const updatedSideAction = { ...action };
						updatedSideAction.number = index + 1;

						// Update it's position
						store.updateAction(updatedSideAction);
					}
				});
			};

			const moveAction = async (action, direction) => {
				if (!currentStep.value) {
					throw new Error(`Step not found`);
				}
				// create a copy of the actions array
				const actions = JSON.parse(JSON.stringify(currentStep.value.actions));

				// find moving action
				const currentAction = actions.find((item) => item.id == action.id);
				if (!currentAction?.id) {
					throw new Error(`Action ID ${action.id} not found in step ${currentStep.value.id}`);
				}

				const currentActionIndex = currentAction.number;
				const adjacentActionIndex = currentActionIndex + (direction === 'up' ? -1 : 1);

				// do nothing if the action is already at the top or bottom of the list
				if (adjacentActionIndex < 0 || adjacentActionIndex > actions.length) {
					return;
				}

				// find adjacent action that should swap position with the moving action
				const adjacentActionPosition = currentAction.number + (direction === 'up' ? -1 : 1);
				const adjacentAction = actions.find((item) => item.number == adjacentActionPosition);

				// swap the two actions
				currentAction.number = currentAction.number + (direction === 'up' ? -1 : 1);
				adjacentAction.number = adjacentAction.number + (direction === 'up' ? 1 : -1);

				// create new step with the reordered actions
				const newStep = { ...currentStep.value };
				newStep.actions = actions;

				await store.updateStep(newStep);
			};

			return {
				switchActionType,
				sortedActions,
				action,
				isValueDisabled,
				isElementDisabled,
				addAction,
				removeAction,
				editAction,
				saveEditAction,
				resetActionsPositions,
				moveAction,
				showButtons,
			};
		},
	};
</script>
