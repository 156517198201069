<template>
	<div class="flex justify-end w-full sm:w-auto sm:mt-0 mt-4 lg:order-2 md:justify-start sm:justify-start justify-center order-1">
		<!-- ADD SCENARIO INPUT -->
		<div v-show="is_input_visible" class="flex w-full sm:justify-end justify-between sm:order-1 order-2">
			<input @keyup.enter="createItem" class="inline-flex items-center text-sm font-medium rounded-md p-2 border border-gray-200 text-gray-500 mr-4 my-1" v-model="new_item" placeholder="Type event name" />
			<!-- ADD SCENARIO CANCEL -->
			<div @click="is_input_visible = false" class="bg-gray-200 hover:bg-gray-300 rounded-md p-2 cursor-pointer my-1">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-gray-400">
					<path
						fill-rule="evenodd"
						d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
						clip-rule="evenodd"
					/>
				</svg>
			</div>
			<!-- ADD SCENARIO VALIDATION -->
			<div @click="createItem" class="bg-blue-200 hover:bg-blue-300 rounded-md p-2 cursor-pointer my-1 ml-2">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-main">
					<path
						fill-rule="evenodd"
						d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm3.059 8.062a.75.75 0 10-.993-1.124 12.785 12.785 0 00-3.209 4.358L9.53 12.22a.75.75 0 00-1.06 1.06l2.135 2.136a.75.75 0 001.24-.289 11.264 11.264 0 013.214-4.815z"
						clip-rule="evenodd"
					/>
				</svg>
			</div>
		</div>
		<button v-show="!is_input_visible" type="button" @click="is_input_visible = true" class="w-full sm:w-auto justify-center button-gto text-white bg-main border border-main order-1 sm:order-2">
			<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 -ml-2" viewBox="0 0 20 20" fill="currentColor">
				<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd" />
			</svg>
			<slot />
		</button>
	</div>
</template>

<script>
	import { ref } from 'vue';

	export default {
		name: 'ButtonAddItem',
		props: {
			step: {
				type: Object,
			},
		},

		setup(props, { emit }) {
			const is_input_visible = ref(false);
			const new_item = ref('New event');
			const createItem = () => {
				emit('createEvent', new_item.value);
        is_input_visible.value = false
        new_item.value = 'New event'
			};
			return { is_input_visible, new_item, createItem };
		},
	};
</script>
