import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/css/main.css';

import { createPinia } from 'pinia';
import axiosPlugin from './axios';

const pinia = createPinia();

const app = createApp(App);

app.config.globalProperties.$http = axiosPlugin;

app.use(pinia);
app.use(router);

app.mount('#app');
