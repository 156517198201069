<template>
	<div class="p-4 my-2 rounded rounded-md" :class="!isExpanded ? 'hover:bg-gray-50' : ''" @click.self="isExpanded = !isExpanded">
		<!-- EVENT NAME -->
		<div class="flex items-center group cursor-pointer" @click.self="isExpanded = !isExpanded">
			<div class="mr-2">
				<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-main" viewBox="0 0 20 20" fill="currentColor">
					<path fill-rule="evenodd" d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z" clip-rule="evenodd" />
				</svg>
			</div>
			<div v-show="!isEditing" @click.self="isExpanded = !isExpanded">{{ event.name }}</div>
			<div><input class="rounded-md border border-gray-200 bg-gray-50 px-3 py-1" type="text" name="current_event_name" v-show="isEditing" v-model="current_event_name" /></div>
			<!-- <div class="text-gray-400 ml-2">{{ event?.assertions?.length }} assertions</div> -->

			<div class="flex items-center mr-auto ml-4">
				<!-- cancel edition or delete -->
				<button v-show="isEditing || isDeleting" @click="[(isEditing = false), (isDeleting = false)]" class="bg-gray-100 hover:bg-blue-100 hover:text-blue-400 text-gray-400 rounded-md p-2">
					<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
						<path fill-rule="evenodd" d="M7.707 3.293a1 1 0 010 1.414L5.414 7H11a7 7 0 017 7v2a1 1 0 11-2 0v-2a5 5 0 00-5-5H5.414l2.293 2.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
					</svg>
				</button>
				<!-- confirm edition -->
				<button v-show="isEditing" @click="updateEventName" class="bg-green-50 hover:bg-green-100 hover:text-green-400 text-green-400 rounded-md p-2 ml-2">
					<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
						<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
					</svg>
				</button>
				<!-- confirm delete -->
				<button v-show="isDeleting" @click="deleteEvent" class="bg-red-50 hover:bg-red-100 hover:text-red-400 text-red-400 rounded-md p-2 ml-2">
					<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
						<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
					</svg>
				</button>
				<!-- edit -->
				<button v-show="isExpanded && !isEditing && !isDeleting" @click="isEditing = true" class="bg-gray-100 hover:bg-blue-100 hover:text-blue-400 text-gray-400 rounded-md p-2">
					<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
						<path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
					</svg>
				</button>
				<!-- delete -->
				<button v-show="isExpanded && !isDeleting && !isEditing" @click="isDeleting = true" class="bg-gray-100 hover:bg-red-100 hover:text-red-400 text-gray-400 rounded-md p-2 ml-2">
					<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
						<path
							fill-rule="evenodd"
							d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
							clip-rule="evenodd"
						/>
					</svg>
				</button>
			</div>
			<svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 ml-4 text-gray-300 group-hover:text-gray-400 duration-100" :class="!isExpanded ? '-rotate-90' : ''" @click="isExpanded = !isExpanded" viewBox="0 0 20 20" fill="currentColor">
				<path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
			</svg>
		</div>
		<!-- EVENT ASSERTIONS -->
		<div class="mt-4 border border-2 border-gray-50 rounded rounded-md" v-show="isExpanded">
			<div class="flex justify-between items-center">
				<div class="py-2 text-gray-400 uppercase text-xs text-center bg-gray-50" :class="!viewOnly ? 'w-1/4' : 'w-1/3'">key</div>
				<div class="py-2 text-gray-400 uppercase text-xs text-center bg-gray-50" :class="!viewOnly ? 'w-1/4' : 'w-1/3'">assertion</div>
				<div class="py-2 text-gray-400 uppercase text-xs text-center bg-gray-50" :class="!viewOnly ? 'w-1/4' : 'w-1/3'">value(s)</div>
				<div v-show="!viewOnly" class="w-1/4 py-2 text-gray-400 uppercase text-xs text-center bg-gray-50">delete</div>
			</div>
			<div v-for="(assertion, assertion_index) in sortedAssertions" class="my-2" :key="assertion_index">
				<div class="flex justify-between items-center">
					<div class="w-1/4 py-2 text-gray-600 text-center break-all">{{ assertion.key.name }}</div>
					<div class="w-1/4 py-2 text-gray-600 text-center break-all">{{ assertion.type }}</div>
					<div class="w-1/4 py-2 text-gray-600 text-center break-all">{{ prettyValue(assertion.values) }}</div>
					<div v-show="!viewOnly" class="w-1/4 flex flex-wrap justify-center py-2 text-gray-600 text-center">
						<!-- <button @click="$router.push({ path: `/assertions` })" class="bg-gray-100 hover:bg-blue-100 hover:text-blue-400 text-gray-400 rounded-md p-2">
							<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
								<path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
							</svg>
						</button> -->

						<button v-show="!showDeleteButtons[assertion_index]" @click="showDeleteButtons[assertion_index] = true" class="bg-gray-100 hover:bg-red-100 text-gray-400 hover:text-red-400 rounded-md p-2 ml-2 hover:cursor-pointer">
							<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
								<path
									fill-rule="evenodd"
									d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
									clip-rule="evenodd"
								/>
							</svg>
						</button>
						<div v-show="showDeleteButtons[assertion_index]" class="flex flex-wrap">
							<button @click="showDeleteButtons[assertion_index] = false" class="bg-gray-100 hover:bg-gray-200 text-gray-400 hover:text-gray-400 rounded-md p-2 ml-2 hover:cursor-pointer">
								<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
									<path fill-rule="evenodd" d="M7.707 3.293a1 1 0 010 1.414L5.414 7H11a7 7 0 017 7v2a1 1 0 11-2 0v-2a5 5 0 00-5-5H5.414l2.293 2.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
								</svg>
							</button>
							<button @click="removeAssertion(assertion)" class="bg-gray-100 hover:bg-red-100 text-gray-400 hover:text-red-400 rounded-md p-2 ml-2 hover:cursor-pointer">
								<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
									<path
										fill-rule="evenodd"
										d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
										clip-rule="evenodd"
									/>
								</svg>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div v-show="!event?.assertions?.length" class="my-2 py-12 bg-gray-50 text-gray-400 uppercase text-xs text-center">no assertion yet</div>
			<div class="flex justify-between items-center py-2" v-if="!viewOnly">
				<div class="w-1/4 pl-2 flex justify-center">
					<select v-model="selected_key" class="w-full text-center bg-gray-50 text-gray-400 uppercase text-xs text-center py-3 appearance-none rounded-md cursor-pointer">
						<option
							class="w-1/2"
							disabled
							:value="{
								value: '',
								type: '',
							}"
						>
							Select key
						</option>
						<option v-for="key in sortedKeys" :key="key.id" :value="key">{{ key.name }}</option>
					</select>
				</div>
				<div class="w-1/4 px-2 flex justify-center">
					<select disabled v-model="selected_key.type" class="w-full bg-gray-50 text-gray-400 uppercase text-xs text-center py-3 rounded-md appearance-none cursor-not-allowed">
						<option disabled value="">Select type</option>
						<option v-for="(type, type_index) in types" :key="type_index" :value="type.type">{{ type.name }}</option>
					</select>
				</div>

				<div v-if="selected_key.type === 'contains'" class="w-1/4 pr-2 flex justify-center relative">
					<div @click="isSelectorVisible = true" class="w-full bg-gray-50 text-gray-400 uppercase text-xs text-center py-3 appearance-none rounded-md cursor-pointer">select values</div>
					<div v-show="isSelectorVisible" class="absolute bg-white top-12 w-full mx-3 border border-gray-50 rounded-md p-2 shadow-xl">
						<div class="flex flex-wrap justify-between items-center px-2">
							<div class="text-gray-600">All</div>
							<input aria-describedby="item-selected" name="item-selected" type="checkbox" class="h-4 w-4 text-gray-300 border-gray-200 rounded" @change="toggleAllValues" />
						</div>
						<div v-for="(value, value_index) in current_value" :key="value_index" :value="value">
							<div class="flex flex-wrap justify-between items-center px-2">
								<div class="text-gray-600">{{ value }}</div>
								<input aria-describedby="item-selected" name="item-selected" type="checkbox" class="h-4 w-4 text-gray-300 border-gray-200 rounded" :checked="selected_values.includes(value)" @change="toggleValue(value)" />
							</div>
						</div>
						<button @click.stop="isSelectorVisible = false" class="w-full mt-4">
							<div class="w-full bg-gray-100 text-xs text-gray-400 text-center rounded py-2">OK</div>
						</button>
					</div>
				</div>

				<div v-else class="w-1/4 pr-2 flex justify-center">
					<select :disabled="typeDontNeedValue" v-model="selected_key.value" class="w-full bg-gray-50 text-gray-400 uppercase text-xs text-center py-3 appearance-none rounded-md" :class="typeDontNeedValue ? 'cursor-not-allowed' : 'cursor-pointer'">
						<option disabled value="">Select value</option>
						<option class="bg-red-300" v-if="!Array.isArray(selected_key.value)" :value="selected_key.value">{{ prettyValue(selected_key.value) }}</option>
						<option class="bg-blue-300" v-else v-for="(value, value_index) in selected_key.value" :key="value_index" :value="value">{{ value }}</option>
					</select>
				</div>
				<div class="w-1/4 flex flex-wrap justify-center">
					<button type="button" @click="resetAssertion()" class="bg-gray-100 hover:bg-gray-200 rounded-md p-2 cursor-pointer my-1">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400 rotate-45" viewBox="0 0 20 20" fill="currentColor">
							<path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
						</svg>
						<span class=""></span>
					</button>
					<button type="button" @click="isAssertionValid ? addAssertion() : null" class="rounded-md p-2 my-1 ml-2" :class="isAssertionValid ? 'bg-blue-100 hover:bg-blue-200' : 'bg-gray-100 cursor-not-allowed'">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" :class="isAssertionValid ? 'text-blue-400' : 'text-gray-400'" viewBox="0 0 20 20" fill="currentColor">
							<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
						</svg>
						<span class=""></span>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { ref, computed, onMounted } from 'vue';
	import { useAssertionStore } from '@/stores/assertions';
	import { useScenarioStore } from '@/stores/scenarios';

	export default {
		name: 'EventAssertions',
		props: {
			event: {
				type: Object,
				default: () => {},
			},
			viewOnly: {
				type: Boolean,
				default: true,
			},
		},

		setup(props) {
			const storeAssertion = useAssertionStore();
			const store = useScenarioStore();

			const keys = computed(() => storeAssertion.keys || []);

			const sortedKeys = keys.value.sort((a, b) => a.name.localeCompare(b.name));
			const sortedAssertions = computed(() => [...props.event.assertions].sort((a, b) => a.key.name.localeCompare(b.key.name)));

			const showDeleteButtons = ref([]);
			const isEditing = ref(false);
			const isDeleting = ref(false);

			const current_event_name = ref('');

			onMounted(() => {
				current_event_name.value = props.event.name;
			});

			const types = ref([
				{
					type: 'equals',
					name: 'Equals',
				},
				{
					type: 'contains',
					name: 'Contains',
				},
				{
					type: 'regex',
					name: 'Match regex',
				},
				{
					type: 'not null',
					name: 'Exists',
				},
			]);

			const selected_key = ref({
				type: '',
				value: '',
			});
			const selected_type = ref(null);
			const selected_value = ref(null);
			const selected_values = ref([]);

			const isSelectorVisible = ref(false);

			const current_value = ref([]);

			const isExpanded = ref(false);

			const toggleAllValues = (event) => {
				if (event.target.checked) {
					selected_values.value = [...current_value.value]; // Select all values if "All" is checked
				} else {
					selected_values.value = []; // Deselect all values if "All" is unchecked
				}
			};

			const toggleValue = (value) => {
				if (selected_values.value.includes(value)) {
					selected_values.value = selected_values.value.filter((v) => v !== value); // Remove value from the list if unchecked
				} else {
					selected_values.value = [...selected_values.value, value]; // Add value to the list if checked
				}
			};

			const isAssertionValid = computed(() => {
				return !!selected_key.value.name && !!selected_key.value.type && !!selected_key.value.values && !eventHasKey.value;
			});

			const resetAssertion = () => {
				selected_key.value = {
					type: '',
					value: '',
				};
			};

			// returns true if assertion is already in event
			const eventHasKey = computed(() => !!props.event.assertions.find((assertion) => assertion.key.name === selected_key.value.name));

			const addAssertion = async () => {
				const assertionInstance = {
					key_id: selected_key.value.id,
					type: selected_key.value.type,
					// values: selected_type.value === 'contains' ? JSON.stringify(selected_values.value) : selected_value.value,
					values: selected_key.value.values,
					event_id: props.event.id,
				};

				if (!eventHasKey.value) {
					store.setLoading(true);
					await storeAssertion.createAssertion(assertionInstance);
					store.setLoading(false);
					resetAssertion();
				}
			};

			const removeAssertion = async (assertion) => {
				store.setLoading(true);
				assertion.event_id = props.event.id;
				await storeAssertion.deleteAssertion(assertion);
				showDeleteButtons.value = [];
				store.setLoading(false);
			};

			const prettyValue = (value) => {
				if (value === '^(?!\\s*$).+' || value === '[]' || value === '[""]' || value === '["[]"]' || value === '' || !value) return '-';
				if (Array.isArray(value)) return value.join(', ');
				if (typeof JSON.parse(value) === 'object') return JSON.parse(value).join(', ');
				return value;
			};

			const typeDontNeedValue = computed(() => {
				return selected_key.value.type === 'equals' || selected_key.value.type === 'not null' || selected_key.value.type === '';
			});

			const updateEventName = async () => {
				const updated_event = { id: props.event.id, name: current_event_name.value, team_id: props.event.team_id };
				store.setLoading(true);
				await storeAssertion.updateEvent(updated_event);
				isEditing.value = false;
				store.setLoading(false);
			};

			const deleteEvent = async () => {
				store.setLoading(true);
				await storeAssertion.deleteEvent(props.event.id);
				isDeleting.value = false;
				isExpanded.value = false;
				store.setLoading(false);
			};

			return {
				keys,
				types,
				selected_key,
				selected_type,
				selected_value,
				selected_values,
				isSelectorVisible,
				isAssertionValid,
				addAssertion,
				resetAssertion,
				current_value,
				prettyValue,
				toggleAllValues,
				toggleValue,
				removeAssertion,
				isExpanded,
				sortedKeys,
				typeDontNeedValue,
				eventHasKey,
				sortedAssertions,
				showDeleteButtons,
				isEditing,
				isDeleting,
				current_event_name,
				updateEventName,
				deleteEvent,
			};
		},
	};
</script>
