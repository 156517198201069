<template>
	<div class="bg-white root flex flex-col sm:flex-row" :class="current_theme + '-theme'">
		<div class="flex sm:flex-col flex-row justify-between relative px-4 sm:px-0 sm:fixed h-auto sm:h-screen w-full sm:w-16 bg-header shadow sm:shadow-none">
			<div class="w-1/2 justify-end sm:justify-center flex sm:block sm:w-11/12 my-2 order-2 sm:order-1">
				<div class="flex flex-wrap">
					<a
						href="/"
						class="flex items-center justify-center w-10 h-10 my-2 ml-2 sm:mx-auto rounded duration-200 hover:text-white hover:bg-opacity-50 hover:bg-main order-3 sm:order-1"
						:class="[isLoading ? 'rotation' : '', route.path == '/' || route.path.includes('scenarios') ? 'bg-main text-white' : 'bg-white text-main']"
					>
						<svg
							v-if="route.path.includes('scenarios') || isLoading"
							version="1.1"
							xmlns="http://www.w3.org/2000/svg"
							xmlns:xlink="http://www.w3.org/1999/xlink"
							class="h-6 w-6"
							fill="currentColor"
							x="0px"
							y="0px"
							viewBox="0 0 24 24"
							xml:space="preserve"
						>
							<g>
								<path
									d="M18,21h-2c-1.7,0-3-1.3-3-3v-2c0-1.7,1.3-3,3-3h2c1.7,0,3,1.3,3,3v2C21,19.7,19.7,21,18,21z M16,15c-0.6,0-1,0.4-1,1v2
									c0,0.6,0.4,1,1,1h2c0.6,0,1-0.4,1-1v-2c0-0.6-0.4-1-1-1H16z M8,21H6c-1.7,0-3-1.3-3-3v-2c0-1.7,1.3-3,3-3h2c1.7,0,3,1.3,3,3v2
									C11,19.7,9.7,21,8,21z M6,15c-0.6,0-1,0.4-1,1v2c0,0.6,0.4,1,1,1h2c0.6,0,1-0.4,1-1v-2c0-0.6-0.4-1-1-1H6z M18,11h-2
									c-1.7,0-3-1.3-3-3V6c0-1.7,1.3-3,3-3h2c1.7,0,3,1.3,3,3v2C21,9.7,19.7,11,18,11z M16,5c-0.6,0-1,0.4-1,1v2c0,0.6,0.4,1,1,1h2
									c0.6,0,1-0.4,1-1V6c0-0.6-0.4-1-1-1H16z M8,11H6c-1.7,0-3-1.3-3-3V6c0-1.7,1.3-3,3-3h2c1.7,0,3,1.3,3,3v2C11,9.7,9.7,11,8,11z"
								></path>
							</g>
						</svg>

						<svg v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
							/>
						</svg>
					</a>
					<a
						href="/assertions"
						class="flex items-center justify-center w-10 h-10 my-2 ml-2 sm:mx-auto rounded duration-200 hover:text-white hover:bg-opacity-50 hover:bg-main order-2 sm:order-2"
						:class="route.path == '/assertions' ? 'bg-main text-white' : 'text-main'"
					>
						<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
							<path
								d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
							/>
						</svg>
					</a>

					<a
						href="/settings"
						class="flex items-center justify-center w-10 h-10 my-2 ml-2 sm:mx-auto rounded duration-200 hover:text-white hover:bg-opacity-50 hover:bg-main order-1 sm:order-3"
						:class="route.path == '/settings' ? 'bg-main text-white' : 'text-main'"
					>
						<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
						</svg>
					</a>
				</div>
			</div>
			<a href="/" class="w-1/2 sm:w-full justify-start sm:justify-center flex sm:block sm:w-11/12 my-2 order-1 sm:order-2">
				<div class="flex items-center sm:justify-center justify-center mx-2 my-2 sm:mx-auto rounded text-white cursor-pointer">
					<img :src="require(`../assets/${current_theme}.png`)" :alt="`${current_theme}-logo`" class="h-5 sm:h-4 mt-2 sm:mt-0 px-1" />
				</div>
			</a>
		</div>
		<div class="hidden sm:block sm:w-full mx-auto sm:w-full-sidebar h-auto sm:h-screen overflow-scroll" :theme="current_theme" :class="current_theme + '-bg'">
			<slot />
		</div>
		<div class="flex sm:hidden h-[calc(100vh-72px)] items-center text-center text-gray-400 px-[10%]">
			<p>Please use a desktop computer to enjoy all the gvrn functionalities</p>
		</div>
	</div>
</template>

<script>
	import { computed, onMounted, ref, watch } from 'vue';
	import { useUserStore } from '@/stores/users';
	import { useScenarioStore } from '@/stores/scenarios';
	import { useRoute } from 'vue-router';

	export default {
		name: 'SidebarLayout',

		setup() {
			const store = useScenarioStore();
			const userStore = useUserStore();
			const route = useRoute();

			const isLoading = computed(() => store.loader) || [];

			const current_theme = computed(() => userStore.getTeam?.theme || 'classic');

			const svgs = ref([
				'M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z',
				'M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z',
				'M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z',
				'M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z',
			]);

			const activeIndex = ref(0);
			const animationInterval = ref(null);

			onMounted(() => {});

			watch(isLoading, (isLoading) => {
				if (isLoading) startAnimation();
				if (!isLoading) stopAnimation();
			});

			const getImageClasses = (index) => {
				return activeIndex.value === index;
			};

			const startAnimation = () => {
				console.log('start');
				setInterval(() => {
					activeIndex.value = (activeIndex.value + 1) % svgs.value.length;
				}, 1000);
			};

			const stopAnimation = () => {
				console.log('stop');

				clearInterval(animationInterval.value);
			};

			return {
				store,
				userStore,
				route,
				current_theme,
				isLoading,
				svgs,
				getImageClasses,
				activeIndex,
				animationInterval,
				startAnimation,
				stopAnimation,
			};
		},
	};
</script>

<style>
	.rotation {
		animation: rotate 1s infinite;
	}

	@keyframes rotate {
		0% {
			transform: rotate(0deg);
		}
		0.001% {
			transform: rotate(90deg);
		}

		24.999% {
			transform: rotate(90deg);
		}
		25% {
			transform: rotate(180deg);
		}
		49.999% {
			transform: rotate(180deg);
		}
		50% {
			transform: rotate(270deg);
		}

		74.999% {
			transform: rotate(270deg);
		}
		75% {
			transform: rotate(360deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
</style>
