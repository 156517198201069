import { useUserStore } from '@/stores/users';

export default (to, from, next) => {
	const store = useUserStore();

	if (!store.token && !store.isAuthenticated) {
		return next('/login');
	}

	next();
};
