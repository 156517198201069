import { defineStore } from 'pinia';
import axios from '../axios';

export const useUserStore = defineStore({
	id: 'users',
	state: () => ({
		user: null,
		token: localStorage.getItem('token') || null,
		isAuthenticated: false,
		team: JSON.parse(localStorage.getItem('team')) || null,
	}),
	getters: {
		// loggedIn: (state) => !!state.user,
		getUser: (state) => state.user,
		getTeam: (state) => state.team,
	},
	actions: {
		setUser(user) {
			this.user = user;
			localStorage.setItem('user', JSON.stringify(user));
		},
		setTeam(team) {
			this.team = team;
			localStorage.setItem('team', JSON.stringify(team));
		},
		setToken(token) {
			this.token = token;
			localStorage.setItem('token', token);
			axios.defaults.headers.common.Authorization = 'Bearer ' + token;
			this.isAuthenticated = true;
		},
		removeToken() {
			this.token = '';
			localStorage.removeItem('token');
			localStorage.removeItem('access_token');
			localStorage.removeItem('refresh_token');
			localStorage.removeItem('user');
			this.isAuthenticated = false;
		},
		// async createUser(credentials) {
		// 	return axios.post('/users/?team_id=1', credentials).then(({ data }) => {
		// 		this.setUser(data);
		// 	});
		// },

		// async getTeam(teamID) {
		// 	const { data } = await axios.get(`/teams/${teamID}`);
		// 	this.setUser(data);
		// 	return data;
		// },
		async updateTeam(payload) {
			return axios.put(`/teams/${payload.team_id}`, payload.team_data).then(({ data }) => {
				this.setTeam(data);
			});
		},
	},
});
