<template>
	<!-- KEY LIST-->
	<div class="w-full mt-4 border border-2 border-gray-50 rounded rounded-md overflow-hidden" :class="!allKeysVisible ? 'h-[350px]' : ''" id="key-panel">
		<div class="flex justify-between items-center">
			<div class="py-2 text-gray-400 uppercase text-xs text-center bg-gray-50 w-1/4">key</div>
			<div class="py-2 text-gray-400 uppercase text-xs text-center bg-gray-50 w-1/4">assertion</div>
			<div class="py-2 text-gray-400 uppercase text-xs text-center bg-gray-50 w-1/4">value(s)</div>
			<div v-if="showCRUD" class="py-2 text-gray-400 uppercase text-xs text-center bg-gray-50 w-1/4">delete</div>
		</div>
		<div v-for="(key_item, key_index) in sortedKeys" class="my-2" :key="key_index">
			<div class="flex justify-between items-center">
				<div class="w-1/4 py-2 text-gray-600 text-center break-none" :title="key_item.name">{{ key_item.name || key_item.key }}</div>
				<div class="w-1/4 py-2 text-gray-600 text-center" :title="key_item.type">{{ key_item.type }}</div>
				<div class="w-1/4 py-2 text-gray-600 text-center break-none" :title="key_item.values">{{ key_item.values }}</div>
				<div v-if="showCRUD" class="w-1/4 flex flex-wrap justify-center py-2 text-gray-600 text-center">
					<button v-show="showDeleteButtons[key_index]" @click="showDeleteButtons[key_index] = false" class="bg-gray-100 hover:bg-gray-200 text-gray-400 hover:text-gray-400 rounded-md p-2 hover:cursor-pointer mr-2">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
							<path fill-rule="evenodd" d="M7.707 3.293a1 1 0 010 1.414L5.414 7H11a7 7 0 017 7v2a1 1 0 11-2 0v-2a5 5 0 00-5-5H5.414l2.293 2.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
						</svg>
					</button>
					<button :disabled="showDeleteButtons[key_index]" @click="setKeyToEdit(key_item)" class="bg-gray-100 text-gray-400 rounded-md p-2 hover:bg-blue-100 hover:text-blue-400">
						<!-- edit icon -->
						<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
							<path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
						</svg>
					</button>

					<button
						v-show="!showDeleteButtons[key_index]"
						@click="showDeleteButtons[key_index] = true"
						:class="showEditButtons[key_index] ? 'bg-gray-50 text-gray-100 ' : ' hover:bg-red-100 hover:text-red-400'"
						class="bg-gray-100 text-gray-400 rounded-md p-2 ml-2"
					>
						<!-- bin icon -->
						<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
							<path
								fill-rule="evenodd"
								d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
								clip-rule="evenodd"
							/>
						</svg>
					</button>
					<button v-show="showDeleteButtons[key_index]" @click="$emit('delete', key_item.id), (showDeleteButtons = [])" class="bg-red-100 text-red-400 hover:bg-red-200 hover:text-red-600 rounded-md p-2 ml-2 hover:cursor-pointer">
						<!-- check icon -->
						<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
							<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
						</svg>
					</button>
				</div>
			</div>
		</div>
		<div v-show="!keys?.length" class="mt-1 h-full flex justify-center items-center bg-gray-50 text-gray-400 uppercase text-xs text-center">No assertion</div>
	</div>
	<div v-show="keys?.length > 6" class="w-full bg-gray-50 text-center py-2 text-gray-400 uppercase text-xs cursor-pointer hover:bg-gray-100 duration-200" @click="allKeysVisible = !allKeysVisible">show {{ allKeysVisible ? 'less' : 'all' }}</div>
	<div id="manage-keys"></div>
	<!-- EDIT KEY INPUTS -->
	<Block v-show="toggleEditKeyBlock && showCRUD" class="mt-6 w-full" :border="true" :title="'Edit key'" :subtitle="''">
		<div class="w-full flex flex-wrap items-start my-6 sm:flex-row flex-col">
			<!-- KEY INPUT -->
			<input type="text" autocomplete="keyName" required="required" v-model="keyToEdit.name" class="input-gto pl-6-i my-4" placeholder="Enter a key" />
			<!-- TYPE INPUT -->
			<select v-model="keyToEdit.type" @change="keyToEdit.values = []" class="button-gto text-white bg-custom-blue pl-6 pr-20 sm:mx-4 mx-0 my-4 border rounded rounded-md appearance-none custom-select">
				<option selected disabled value="">Select operator</option>
				<option value="not null">Exists</option>
				<option value="equals">Equals</option>
				<option value="contains">Contains</option>
				<option value="regex">Match Regex</option>
			</select>
			<!-- VALUE INPUT -->
			<div class="flex flex-wrap flex-col sm:flex-row justify-between my-4">
				<div v-if="keyToEdit.type === 'regex'" class="w-3/4">
					<input type="text" autocomplete="keyName" required="required" v-model="newKey.values[0]" class="input-gto pl-6-i" placeholder="Enter a regex" />
				</div>
				<div v-else class="flex flex-col">
					<div class="flex flex-wrap">
						<input
							v-if="keyToEdit.type === 'equals'"
							type="text"
							:disabled="keyToEdit.type === 'not null'"
							required="required"
							v-model="keyToEdit.values"
							class="input-gto pl-6-i"
							:class="keyToEdit.type === 'not null' ? 'cursor-not-allowed' : ''"
							placeholder="Enter a value"
						/>

						<input v-else type="text" :disabled="keyToEdit.type === 'not null'" required="required" v-model="value_to_edit" class="input-gto pl-6-i" :class="keyToEdit.type === 'not null' ? 'cursor-not-allowed' : ''" placeholder="Add values" />
						<button v-if="keyToEdit.type === 'contains'" @click="addValue('edit')" type="button" class="button-gto text-white bg-custom-blue button-gto--custom mt-4 sm:mt-0 flex justify-center ml-2">
							<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
								<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd"></path>
							</svg>
						</button>
					</div>
					<div v-if="keyToEdit.type === 'contains' && keyToEdit?.values.length">
						<div v-for="(item, subindex) in JSON.parse(keyToEdit?.values)" :key="subindex" class="mt-2 mr-16">
							<div class="flex items-center flex-wrap justify-between w-full px-4 py-2 bg-gray-50 text-gray-400 text-sm rounded-sm my-1">
								<div>{{ item }}</div>
								<div @click="removeValue(subindex)" class="cursor-pointer">
									<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
										<path
											fill-rule="evenodd"
											d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
											clip-rule="evenodd"
										></path>
									</svg>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="w-full flex justify-start xs:justify-end ml-0 xs:ml-auto my-4">
				<button
					@click="
						toggleEditKeyBlock = false;
						resetKey();
					"
					type="button"
					class="button-gto button-gto--custom mt-0 flex justify-center bg-gray-100 text-gray-400 border border-gray-200"
				>
					Cancel
				</button>
				<!-- UPDATE KEY BUTTON -->
				<button
					:disabled="!isKeyToEditValid"
					@click="$emit('update', keyToEdit), resetKey()"
					type="button"
					class="button-gto button-gto--custom mt-0 flex justify-center ml-4"
					:class="isKeyToEditValid ? 'text-white bg-custom-blue' : 'bg-gray-100 text-gray-400 border border-gray-200 cursor-not-allowed'"
				>
					update key
				</button>
			</div>
		</div>
	</Block>
	<!-- NEW KEY INPUTS -->
	<Block v-show="!toggleEditKeyBlock && showCRUD" class="mt-6 min-w-full" :border="true" :title="'Add new assertion'" :subtitle="''">
		<div class="w-full flex flex-wrap items-start my-6 sm:flex-row flex-col">
			<!-- KEY INPUT -->
			<input type="text" autocomplete="keyName" required="required" v-model="newKey.name" class="input-gto pl-6-i my-4" placeholder="Enter a key" />
			<!-- TYPE INPUT -->
			<select v-model="newKey.type" @change="newKey.values = []" class="button-gto text-white bg-custom-blue pl-6 pr-20 sm:mx-4 mx-0 my-4 border rounded rounded-md appearance-none custom-select">
				<option selected disabled value="">Select operator</option>
				<option value="not null">Exists</option>
				<option value="equals">Equals</option>
				<option value="contains">Contains</option>
				<option value="regex">Match Regex</option>
			</select>
			<!-- VALUE INPUT -->
			<div class="flex flex-wrap flex-col sm:flex-row justify-between my-4">
				<div v-if="newKey.type === 'regex'" class="w-3/4">
					<input type="text" autocomplete="keyName" required="required" v-model="newKey.values[0]" class="input-gto pl-6-i" placeholder="Enter a regex" />
				</div>
				<div v-else class="flex flex-col">
					<div class="flex flex-wrap">
						<input
							v-if="newKey.type === 'equals'"
							type="text"
							:disabled="newKey.type === 'not null'"
							required="required"
							v-model="newKey.values"
							class="input-gto pl-6-i"
							:class="newKey.type === 'not null' ? 'cursor-not-allowed' : ''"
							placeholder="Enter a value"
						/>

						<input v-else type="text" :disabled="newKey.type === 'not null'" required="required" v-model="new_value" class="input-gto pl-6-i" :class="newKey.type === 'not null' ? 'cursor-not-allowed' : ''" placeholder="Add value(s)" />
						<button v-if="newKey.type === 'contains'" @click="addValue('new')" type="button" class="button-gto text-white bg-custom-blue button-gto--custom mt-4 sm:mt-0 flex justify-center ml-2">
							<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
								<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd"></path>
							</svg>
						</button>
					</div>
					<div v-if="newKey.type === 'contains' && newKey?.values.length">
						<div v-for="(item, subindex) in newKey?.values" :key="subindex" class="mt-2 mr-16">
							<div class="flex items-center flex-wrap justify-between w-full px-4 py-2 bg-gray-50 text-gray-400 text-sm rounded-sm my-1">
								<div>{{ item }}</div>
								<div @click="removeValue(subindex)" class="cursor-pointer">
									<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
										<path
											fill-rule="evenodd"
											d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
											clip-rule="evenodd"
										></path>
									</svg>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="w-full flex justify-start xs:justify-end ml-0 xs:ml-auto my-4">
				<!-- CANCEL ADD KEY -->
				<button @click="resetKey" type="button" class="button-gto button-gto--custom mt-0 flex justify-center bg-gray-100 text-gray-400 border border-gray-200">Cancel</button>
				<!-- ADD KEY BUTTON -->
				<button
					:disabled="!isKeyValid"
					@click="$emit('create', newKey), resetKey()"
					type="button"
					class="button-gto button-gto--custom mt-0 flex justify-center ml-4"
					:class="isKeyValid ? 'text-white bg-custom-blue' : 'bg-gray-100 text-gray-400 border border-gray-200 cursor-not-allowed'"
				>
					OK
				</button>
			</div>
		</div>
	</Block>
</template>

<script>
	import Block from '@/components/Block';
	import { computed, ref } from 'vue';

	export default {
		name: 'KeyTable',
		props: {
			keys: {
				type: Array,
				default: () => [],
			},
			showCRUD: {
				type: Boolean,
				default: true,
			},
		},
		components: { Block },
		emits: ['create', 'update', 'delete'],
		setup(props) {
			const sortedKeys = computed(() => [...props?.keys].sort((a, b) => a.name?.localeCompare(b?.name)) || []);

			const new_value = ref('');
			const value_to_edit = ref('');

			const newKey = ref({
				name: '',
				type: '',
				values: [],
			});

			const keyToEdit = ref({
				name: '',
				type: '',
				values: [],
			});

			const allKeysVisible = ref(false);
			const showDeleteButtons = ref([]);
			const showEditButtons = ref([]);
			const toggleEditKeyBlock = ref(false);

			const resetKey = () => {
				newKey.value = {
					name: '',
					type: '',
					values: [],
				};
				new_value.value = '';
				keyToEdit.value = {
					name: '',
					type: '',
					values: [],
				};
				value_to_edit.value = '';
			};

			const prettyValue = (value) => {
				// if (value === '^(?!\\s*$).+' || value === '[]' || value === '[""]' || value === '["[]"]' || value === '' || !value) return '-';
				// if (Array.isArray(value)) return value.join(', ');
				// if (typeof JSON.parse(value) === 'object') return JSON.parse(value).join(', ');
				return value;
			};

			const isKeyValid = computed(() => {
				if (newKey.value.type === 'not null') {
					return !!newKey.value.name && !!newKey.value.type;
				}
				return !!newKey.value.name && !!newKey.value.type && newKey.value.values.length;
			});

			const isKeyToEditValid = computed(() => {
				if (keyToEdit.value.type === 'not null') {
					return !!keyToEdit.value.name && !!keyToEdit.value.type;
				}
				return !!keyToEdit.value.name && !!keyToEdit.value.type && keyToEdit.value.values.length;
			});

			const setKeyToEdit = (key) => {
				toggleEditKeyBlock.value = true;
				// let valuesToSet = '';
				// if (key.values === '^(?!\\s*$).+' || key.values === '[]' || key.values === '' || !key.values) {
				// 	valuesToSet = key.values;
				// } else {
				// 	valuesToSet = JSON.parse(key.values);
				// }
				keyToEdit.value = {
					name: key.name || key.key,
					type: key.type,
					values: key.values,
					id: key.id,
				};
				scrollToBottom();
			};

			const addValue = async (mode) => {
				const current_key = mode === 'new' ? newKey.value : keyToEdit.value;
				console.log('current_key', current_key);
				let current_value = mode === 'new' ? new_value.value : value_to_edit.value;
				console.log('current_value', current_value);
				if (current_value.length) {
					const parsedValues = JSON.parse(current_key.values);
					parsedValues.push(current_value);
					current_key.values = JSON.stringify(parsedValues);
					current_value = '';
				}
			};

			const scrollToBottom = () => {
				const panel = document.querySelector('#manage-keys');
				panel.scrollIntoView();
			};

			return {
				sortedKeys,
				newKey,
				prettyValue,
				isKeyValid,
				isKeyToEditValid,
				keyToEdit,
				showDeleteButtons,
				showEditButtons,
				toggleEditKeyBlock,
				resetKey,
				new_value,
				value_to_edit,
				allKeysVisible,
				setKeyToEdit,
				addValue,
			};
		},
	};
</script>
