<template>
	<div class="flex items-center">
		<button
			@click="!isLoading ? toggle() : null"
			:value="current_status"
			:class="[current_status ? 'bg-custom-blue' : 'bg-gray-200', isLoading ? 'cursor-not-allowed' : 'cursor-pointer']"
			type="button"
			class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200"
			role="switch"
			aria-checked="false"
			aria-labelledby="annual-billing-label"
		>
			<span aria-hidden="true" :class="current_status ? 'translate-x-5' : 'translate-x-0'" class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
		</button>
		<span class="ml-3 leading-none">
			<span class="text-sm font-medium text-gray-900 xl:text-xs">
				<slot></slot>
			</span>
		</span>
	</div>
</template>

<script>
	import { ref, watch, onBeforeMount } from 'vue';

	export default {
		name: 'UIToggle',
		props: {
			status: {
				type: Boolean,
				required: true,
				default: false,
			},
			input: {
				type: String,
				default: 'input',
			},
			loading: {
				type: Boolean,
				default: false,
			},
		},
		setup(props, { emit }) {
			const current_status = ref(false);
			const isLoading = ref(false);

			onBeforeMount(async () => {
				current_status.value = props.status;
			});

			watch(
				() => props.status,
				(new_status) => {
					console.log('toggle watch new status', new_status);
					current_status.value = new_status;
				}
			);

			watch(
				() => props.loading,
				(new_loading) => {
					isLoading.value = new_loading;
				}
			);

			// FUNCTIONS

			const toggle = () => {
				current_status.value = !current_status.value;
				emit(props.input, current_status.value);
			};

			return {
				current_status,
				isLoading,
				toggle,
			};
		},
	};
</script>
