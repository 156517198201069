<template>
	<SidebarLayout>
		<div class="w-11/12 mx-auto">
			<!-- HEADING -->
			<Block class="mt-6 sm:mt-10" :border="true">
				<div class="flex flex-wrap align-center justify-between w-full">
					<div class="flex w-full sm:w-auto">
						<div class="flex items-center justify-center w-11 h-11 bg-main rounded">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-6 w-6 text-white">
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
								></path>
							</svg>
						</div>
						<div class="ml-3">
							<h1 class="w-full font-bold text-2xl m-0 leading-none">Dashboard</h1>
							<p class="w-full mt-1 leading-none">Overview of the scenarios</p>
						</div>
					</div>
					<!-- ADD SCENARIO WRAPPER -->
					<div class="flex justify-end w-full sm:w-auto sm:mt-0 mt-4 lg:order-2 md:justify-start sm:justify-start justify-center order-1">
						<!-- ADD SCENARIO INPUT -->
						<div v-show="toggleCreate" class="flex w-full sm:justify-end justify-between sm:order-1 order-2">
							<input @keyup.enter="createScenario" class="inline-flex items-center text-sm font-medium rounded-md p-2 border border-gray-200 text-gray-500 mr-4 my-1" v-model="new_scenario.name" placeholder="Enter scenario name" />
							<!-- ADD SCENARIO CANCEL -->
							<div @click="toggleCreate = false" class="bg-gray-200 hover:bg-gray-300 rounded-md p-2 cursor-pointer my-1">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-gray-400">
									<path
										fill-rule="evenodd"
										d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
										clip-rule="evenodd"
									/>
								</svg>
							</div>
							<!-- ADD SCENARIO VALIDATION -->
							<div @click="createScenario" class="bg-blue-200 hover:bg-blue-300 rounded-md p-2 cursor-pointer my-1 ml-2">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-main">
									<path
										fill-rule="evenodd"
										d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm3.059 8.062a.75.75 0 10-.993-1.124 12.785 12.785 0 00-3.209 4.358L9.53 12.22a.75.75 0 00-1.06 1.06l2.135 2.136a.75.75 0 001.24-.289 11.264 11.264 0 013.214-4.815z"
										clip-rule="evenodd"
									/>
								</svg>
							</div>
						</div>

						<!-- SHOW ADD SCENARIO INPUT -->
						<button v-show="!toggleCreate" type="button" @click="toggleCreate = true" class="w-full sm:w-auto justify-center button-gto text-white bg-main border border-main order-1 sm:order-2">
							<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 -ml-2" viewBox="0 0 20 20" fill="currentColor">
								<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd" />
							</svg>
							Add Scenario
						</button>
					</div>
				</div>
			</Block>

			<!-- CONTENT -->
			<Block :border="true">
				<!-- FILTER INPUTS & ADD SCENARIO BUTTON -->
				<div class="w-full flex lg:flex-nowrap justify-between flex-wrap">
					<div class="flex w-full md:flex-nowrap lg:order-1 lg:w-9/12 lg:mt-0 sm:w-full order-2 flex-wrap">
						<!-- NAME FILTER -->
						<div class="w-full sm:mr-2 lg:w-1/3 md:my-0 sm:w-4/6 text-sm mb-2">
							<div class="relative">
								<input id="search" autocomplete="false" v-model="filter.search" class="input-gto w-full" placeholder="Search for a scenario" type="search" />
							</div>
						</div>
						<!-- DEVICE FILTER -->
						<div class="w-full py-4 sm:py-0 lg:w-1/6 md:w-2/6 md:my-0 sm:w-2/6">
							<select v-model="filter.device" name="devices" id="devices" class="input-gto w-full appearance-none custom-select custom-select--white text-sm">
								<option value="" disabled class="inline-flex items-center px-6 py-2 border border-transparent text-sm font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200">Device</option>
								<option value="desktop" class="inline-flex items-center px-6 py-2 border border-transparent text-sm font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200">Desktop</option>
								<option value="mobile" class="inline-flex items-center px-6 py-2 border border-transparent text-sm font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200">Mobile</option>
							</select>
						</div>

						<!-- STATUS FILTER -->
						<div class="w-full py-4 sm:py-0 sm:ml-2 lg:w-1/6 md:w-2/6 md:my-0 sm:w-2/6">
							<select v-model="filter.status" name="status" id="status" class="input-gto w-full appearance-none custom-select custom-select--white text-sm">
								<option value="" disabled class="inline-flex items-center px-6 py-2 border border-transparent text-sm font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200">Status</option>
								<option value="passed" class="inline-flex items-center px-6 py-2 border border-transparent text-sm font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200">Passed</option>
								<option value="failed" class="inline-flex items-center px-6 py-2 border border-transparent text-sm font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200">Failed</option>
							</select>
						</div>

						<!-- RESET FILTERS -->
						<div class="w-2/6 justify-start md:w-2/6 md:my-0 w-full flex mt-2 md:ml-2">
							<button v-if="hasFilters" @click="resetFilter" class="button-gto bg-gray-100 hover:bg-gray-200 text-gray-500 border-gray-300 text-sm px-4 cursor-pointer md:my-0 sm:m-0">
								<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2 -ml-2 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
									<path
										fill-rule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clip-rule="evenodd"
									/>
								</svg>
								Reset filters
							</button>
						</div>
					</div>
				</div>

				<!-- SCENARIOS TABLE -->
				<div class="w-full sm:mt-2">
					<div class="flex flex-col">
						<div class="mt-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div class="pt-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
								<div class="overflow-hidden border border-gray-200 sm:rounded-lg">
									<table class="min-w-full divide-y divide-gray-200">
										<!-- TABLE HEAD -->
										<thead class="bg-gray-50">
											<tr class="hidden sm:table-row">
												<th scope="col" class="custom-th">Status</th>
												<th scope="col" class="custom-th">Name</th>
												<th scope="col" class="custom-th">Runs</th>
												<th scope="col" class="custom-th">ID</th>
												<th scope="col" class="custom-th">Last Run</th>
												<th scope="col" class="custom-th">Device</th>
												<th scope="col" class="custom-th"></th>
											</tr>
										</thead>
										<tbody v-if="isLoading">
											<tr>
												<td colspan="6" class="text-center text-sm font-light text-gray-400 py-8">Loading...</td>
											</tr>
										</tbody>
										<!-- TABLE BODY -->
										<tbody v-else class="bg-white divide-y divide-gray-200">
											<tr v-for="scenario in filteredScenarios" @click="goToScenario(scenario.id)" :key="scenario.id" class="cursor-pointer hover:bg-gray-100 hidden sm:table-row">
												<!-- STATUS -->
												<td class="px-6 py-4 whitespace-nowrap">
													<span v-if="getStatus(scenario) === 'no-data'" class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 hover:bg-yelow-200 text-yellow-800"> No data </span>
													<span v-else-if="getStatus(scenario) === 'work-in-progress'" class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800" title="Work in progress"> WIP </span>
													<span v-else-if="getStatus(scenario) === 'passed'" class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-custom-green text-white"> Passed </span>
													<span v-else class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-custom-red hover:bg-custom-red-darker text-white"> Failed </span>
												</td>

												<!-- NAME -->
												<td class="px-6 py-4 whitespace-nowrap">
													<div class="text-sm font-medium text-gray-900">
														{{ scenario.name }}
													</div>
												</td>

												<!-- RUN BARS -->
												<td class="px-6 py-4 whitespace-nowrap">
													<a
														v-for="run in scenario.runs.slice(-28)"
														:key="run.id"
														:href="'/scenarios/' + scenario.id + '/runs/' + run.id"
														data-action="run"
														:class="[scenario.work_in_progress ? 'bg-yellow-100 hover:bg-yellow-200 text-yellow-800' : run.status ? 'bg-custom-green hover:bg-custom-green-darker' : 'bg-custom-red hover:bg-custom-red-darker']"
														class="mx-0.5 px-1 inline-flex text-xs leading-5 font-semibold rounded-full h-10"
													>
													</a>
												</td>

												<!-- ID -->
												<td class="px-6 py-4 whitespace-nowrap">
													<div class="text-sm text-gray-900">
														{{ scenario.id }}
													</div>
												</td>

												<!-- LAST RUN TIME -->
												<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
													{{ prettyDate(scenario.last_run_on) }}
												</td>

												<!-- DEVICE ICON -->
												<td class="px-6 py-4 whitespace-nowrap text-center text-sm font-medium">
													<div v-if="scenario.device === 'mobile'" class="h-8 w-8">
														<svg class="svg-icon text-gray-400" viewBox="0 0 20 20">
															<path
																d="M13.372,1.781H6.628c-0.696,0-1.265,0.569-1.265,1.265v13.91c0,0.695,0.569,1.265,1.265,1.265h6.744c0.695,0,1.265-0.569,1.265-1.265V3.045C14.637,2.35,14.067,1.781,13.372,1.781 M13.794,16.955c0,0.228-0.194,0.421-0.422,0.421H6.628c-0.228,0-0.421-0.193-0.421-0.421v-0.843h7.587V16.955z M13.794,15.269H6.207V4.731h7.587V15.269z M13.794,3.888H6.207V3.045c0-0.228,0.194-0.421,0.421-0.421h6.744c0.228,0,0.422,0.194,0.422,0.421V3.888z"
															></path>
														</svg>
													</div>
													<div v-else class="h-8 w-8">
														<svg class="svg-icon text-gray-400" viewBox="0 0 20 20">
															<path
																d="M17.237,3.056H2.93c-0.694,0-1.263,0.568-1.263,1.263v8.837c0,0.694,0.568,1.263,1.263,1.263h4.629v0.879c-0.015,0.086-0.183,0.306-0.273,0.423c-0.223,0.293-0.455,0.592-0.293,0.92c0.07,0.139,0.226,0.303,0.577,0.303h4.819c0.208,0,0.696,0,0.862-0.379c0.162-0.37-0.124-0.682-0.374-0.955c-0.089-0.097-0.231-0.252-0.268-0.328v-0.862h4.629c0.694,0,1.263-0.568,1.263-1.263V4.319C18.5,3.625,17.932,3.056,17.237,3.056 M8.053,16.102C8.232,15.862,8.4,15.597,8.4,15.309v-0.89h3.366v0.89c0,0.303,0.211,0.562,0.419,0.793H8.053z M17.658,13.156c0,0.228-0.193,0.421-0.421,0.421H2.93c-0.228,0-0.421-0.193-0.421-0.421v-1.263h15.149V13.156z M17.658,11.052H2.509V4.319c0-0.228,0.193-0.421,0.421-0.421h14.308c0.228,0,0.421,0.193,0.421,0.421V11.052z"
															></path>
														</svg>
													</div>
												</td>
												<td class="px-6 py-4 whitespace-nowrap text-center text-sm font-medium" data-test="copy">
													<div @click.stop="duplicateScenario(scenario.id)" data-test="copy" class="bg-gray-100 hover:bg-gray-200 rounded-md p-2 cursor-pointer my-1 w-max">
														<svg xmlns="http://www.w3.org/2000/svg" data-test="copy" class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
															<path d="M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z" data-test="copy" />
															<path d="M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z" data-test="copy" />
														</svg>
													</div>
												</td>
											</tr>
											<tr v-if="!filteredScenarios.length">
												<td colspan="6" class="text-center text-sm font-light text-gray-400 py-8">no results</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Block>
		</div>
	</SidebarLayout>
</template>

<script>
	import { useScenarioStore } from '@/stores/scenarios';
	import { ref, onMounted, computed } from 'vue';
	import { useRouter } from 'vue-router';

	import { prettyDate } from '../utils/date.js';

	import Block from '@/components/Block';
	import SidebarLayout from '@/layouts/SidebarLayout.vue';

	export default {
		name: 'HomeView',
		props: {},
		components: { Block, SidebarLayout },
		setup() {
			const store = useScenarioStore();
			const router = useRouter();

			const filter = ref({
				status: '',
				device: '',
				search: '',
			});

			const isLoading = computed(() => store.loader) || [];

			const toggleCreate = ref(false);
			const new_scenario = ref({
				name: 'New scenario',
				description: '',
				device: 'desktop',
				frequency: '',
			});

			onMounted(async () => {
				store.setLoading(true);
				await store.getScenarios();
				store.setLoading(false);
			});

			// COMPUTED
			// Return scenarios filtered by status, device or user input
			const filteredScenarios = computed(() => {
				let result = store.scenarios;
				// filter by status
				if (filter.value.status !== '') {
					result = result.filter((scenario) => getStatus(scenario) === filter.value.status);
				}
				// filter by device
				if (filter.value.device !== '') {
					result = result.filter((scenario) => scenario.device.toLowerCase() === filter.value.device.toLowerCase());
				}
				// filter by user input
				if (filter.value.search !== '') {
					const search_input = filter.value.search.toLowerCase();
					result = result.filter((scenario) => ['name', 'id', 'device'].some((key) => String(scenario[key]).toLowerCase().includes(search_input)));
				}
				return result.sort((a, b) => b.id - a.id);
			});

			// Returns true if some filters are enabled
			const hasFilters = computed(() => {
				return !!filter.value.device || !!filter.value.search || !!filter.value.status;
			});

			// FUNCTIONS

			const goToScenario = (scenarioID) => {
				router.push({ name: 'scenario', params: { id: scenarioID } });
			};

			const getStatus = (scenario) => {
				if (scenario.runs.length < 1) {
					return 'no-data';
				} else if (scenario.work_in_progress) {
					return 'work-in-progress';
				} else if (scenario.runs[scenario.runs.length - 1].status) {
					return 'passed';
				} else {
					return 'failed';
				}
			};

			const resetFilter = () => {
				filter.value.status = '';
				filter.value.device = '';
				filter.value.search = '';
			};

			// ACTIONS
			const createScenario = async () => {
				await store.createScenario(new_scenario.value);
				toggleCreate.value = false;
			};

			const duplicateScenario = async (scenarioID) => {
				await store.copyScenario(scenarioID);
			};

			return {
				filter,
				filteredScenarios,
				toggleCreate,
				new_scenario,
				prettyDate,
				isLoading,
				hasFilters,
				createScenario,
				duplicateScenario,
				resetFilter,
				getStatus,
				goToScenario,
			};
		},
	};
</script>
