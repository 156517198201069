<template>
	<div class="mx-auto my-6">
		<div :class="[border ? 'border px-4 py-4 sm:px-8 sm:py-8' : '']" class="flex flex-wrap items-center justify-start bg-white rounded-lg border-gray-200 py-5 rounded-xl px-4 sm:px-8">
			<p v-if="title" class="w-full mb-4 text-lg">{{ title }}</p>
			<p v-if="subtitle" class="w-full mb-4 text-sm text-gray-500">
				{{ subtitle }}
			</p>
			<slot />
		</div>
	</div>
</template>
x
<script>
	export default {
		// eslint-disable-next-line vue/multi-word-component-names
		name: 'Block',
		props: ['title', 'subtitle', 'border'],
	};
</script>
