<template>
	<SidebarLayout>
		<div class="w-11/12 mx-auto">
			<Block :border="true">
				<div class="flex flex-wrap align-center justify-between w-full">
					<div class="flex w-full sm:w-auto">
						<div class="flex items-center justify-center w-11 h-11 bg-main rounded">
							<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor">
								<path
									d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
								/>
							</svg>
						</div>
						<div class="ml-3">
							<h1 class="w-full font-bold text-2xl m-0 leading-none">Assertions</h1>
							<p class="w-full mt-1 leading-none">Overview of global assertions</p>
						</div>
					</div>
					<div class="flex gap-6 pt-6 md:pt-0">
						<ButtonAddItem @createEvent="createEvent">Add event</ButtonAddItem>
						<button type="button" @click="toggleKeyPanel" class="w-full sm:w-auto justify-center button-gto text-white bg-main border border-main order-1 sm:order-2">Manage keys</button>
					</div>
				</div>
			</Block>
			<div v-if="isLoading && !events.length">
				<div v-for="(skeleton, skeleton_index) in [1, 2, 3, 4, 5]" :key="skeleton_index">
					<div class="p-4 my-2 rounded rounded-md bg-white border border-gray-200">
						<div class="flex items-center">
							<div class="bg-gray-100 w-6 h-6 mr-4 rounded-sm animate-pulse"></div>
							<div class="bg-gray-100 w-32 h-6 rounded-sm animate-pulse"></div>
							<svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 ml-auto text-gray-300 duration-100 -rotate-90" viewBox="0 0 20 20" fill="currentColor">
								<path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path>
							</svg>
						</div>
					</div>
				</div>
			</div>

			<div v-else-if="events.length">
				<div v-for="(event, event_index) in sortedEvents" :key="event_index">
					<EventAssertions :event="event" class="bg-white border border-gray-200" :viewOnly="false"></EventAssertions>
				</div>
			</div>
			<div v-else>
				<div class="flex flex-wrap items-center justify-center py-12 rounded rounded-md bg-white border border-gray-200 text-gray-400">
					<p class="w-full text-center mb-4">No global assertion</p>
					<ButtonAddItem @createEvent="createEvent">Add event</ButtonAddItem>
				</div>
			</div>
			<!-- KEY PANEL -->
			<SidePanel @togglePanel="toggleKeyPanel" title="Manage keys" :isVisible="showKeyPanel" :isLarge="true">
				<KeyTable :keys="keys" @create="createKey" @update="updateKey" @delete="deleteKey"></KeyTable>
			</SidePanel>
		</div>
	</SidebarLayout>
</template>

<script>
	import { ref, computed, onMounted } from 'vue';

	import Block from '@/components/Block';
	import SidebarLayout from '@/layouts/SidebarLayout.vue';
	import dataLayer from '../../dataLayer.json';
	import EventAssertions from '@/components/EventAssertions.vue';
	import ButtonAddItem from '@/components/ButtonAddItem.vue';
	import KeyTable from '@/components/KeyTable.vue';
	import SidePanel from '@/components/SidePanel';

	import { useAssertionStore } from '@/stores/assertions';
	import { useScenarioStore } from '@/stores/scenarios';

	export default {
		name: 'AssertionsView',
		props: {},
		emits: ['test'],
		components: { SidePanel, Block, SidebarLayout, EventAssertions, ButtonAddItem, KeyTable },
		setup() {
			const store = useScenarioStore();

			const storeAssertion = useAssertionStore();
			const errors = ref('');
			const showKeyPanel = ref(false);

			const events = computed(() => storeAssertion.events);
			const keys = computed(() => storeAssertion.keys);
			const isLoading = computed(() => store.loader) || [];

			const sortedEvents = computed(() => [...events.value].sort((a, b) => a.name.localeCompare(b.name)));

			onMounted(async () => {
				store.setLoading(true);
				await storeAssertion.getKeys();
				await storeAssertion.getEvents();
				store.setLoading(false);
			});

			const toggleKeyPanel = () => {
				showKeyPanel.value = !showKeyPanel.value;
			};

			const createKey = async (newKey) => {
				let body = newKey;
				if (typeof body.values === 'string') body.values = [body.values];
				// Add check for data
				store.setLoading(true);
				await storeAssertion.createKey(body);
				store.setLoading(false);
			};

			const updateKey = async (keyToEdit) => {
				let body = keyToEdit;
				if (typeof body.values === 'string') body.values = [body.values];
				// Add check for data
				store.setLoading(true);
				await storeAssertion.updateKey(body);
				store.setLoading(false);
			};

			const deleteKey = async (keyID) => {
				await storeAssertion.deleteKey(keyID);
			};

			const JSONoptions = ref({
				expandDepth: 1,
				copyable: false,
				boxed: false,
				theme: 'jv-light',
			});

			const parsedDataLayer = computed(() => {
				return JSON.parse(dataLayer);
			});

			const createEvent = async (new_event) => {
				store.setLoading(true);
				await storeAssertion.createEvent({ name: new_event });
				store.setLoading(false);
			};

			return {
				parsedDataLayer,
				dataLayer,
				JSONoptions,
				createEvent,
				events,
				toggleKeyPanel,
				showKeyPanel,
				errors,
				createKey,
				isLoading,
				sortedEvents,
				keys,
				deleteKey,
				updateKey,
			};
		},
	};
</script>
